import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { UserModel } from '../auth.models';
import { ActivatedRoute, Router } from '@angular/router';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { NotificationType } from '../../interfaces/notification.enums';
import { ClientRoles } from '../../services/auth/auth.models'


@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnDestroy{

  public loginForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedLogin = false;

  private subs: Array<Subscription> = [];

  constructor(
    public formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit() {
    this.subs.push(
        this.activatedRoute.paramMap.pipe(
          map(params => params.get('uuid')),
          switchMap(uuid => this.authService.validate(uuid)),
          catchError(error => {
            return throwError(error);
          })
        ).subscribe(resolve => {
          console.log(resolve);
          if (resolve.Message == 'Cuenta validada') {
            this.handleUserValidate();
          }
          else{
            this.errorUserValidate();
          }
        })
      );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private handleUserValidate() {
    const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: 'Tu cuenta ha sido validada exitosamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }

  private errorUserValidate() {
    const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: 'Ocurrio un error al validar su cuenta',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.error(toastOptions);
  }

}
