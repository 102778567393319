<div class="bg-white">
    <div class="container bg-white py-3">
        <div class="row py-3">
            <app-breadcrumb-two></app-breadcrumb-two>
        </div>
        <div class="row">
            <div class="col-12 col-md-3">
                <div class="row">
                    <div class="col-12 col-md-5 px-0">
                        <div class="profile-image">
                            <img class="image-profile" *ngIf="user?.profilePhotoUrl" [src]="user?.profilePhotoUrl"
                                alt="">
                            <img class="image-profile" *ngIf="!user?.profilePhotoUrl"
                                src="../../../../assets/images/avatar.png" alt="">
                        </div>
                    </div>
                    <div class="col-12 col-md-7 px-0 primary-data">
                        <h5>{{ user?.User.firstName }} <br> {{ user?.User.lastName }}</h5>
                        <span *ngIf="user?.profile?.position">Dirección de Desarrollo, Inovación y Gobierno
                            Abierto</span>
                    </div>
                </div>
                <div class="">
                    <nav>
                        <ul class="primary-menu">
                            <li class="menu-item" [ngClass]="{ 'active': location === 'Data' }"
                                (click)="location = 'Data'">Mis Datos</li>
                            <li class="menu-item" [ngClass]="{ 'active': location === 'Expediente' }"
                                (click)="location = 'Expediente'">Mi Expediente Electrónico</li>
                            <li class="menu-item" [ngClass]="{ 'active': location === 'Tramites' }"
                                (click)="location = 'Tramites'">Mis Trámites</li>
                            <li class="menu-item">Mis Servicios</li>
                            <li class="menu-item">Mis Pagos</li>
                            <li class="menu-item">Mis Citas</li>
                            <li class="menu-item">Mis Postulaciones</li>
                        </ul>
                        <ul class="secondary-menu">
                            <li class="menu-item">Cambiar contraseña</li>
                            <li class="menu-item" (click)="logout()">Cerrar sesión</li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="col-12 col-md-9">
                <app-data *ngIf="location === 'Data'" [id]="userId" class="w-100"></app-data>
                <app-record-documents *ngIf="location === 'Expediente'" [id]="userId"
                    class="w-100"></app-record-documents>
                <app-list-tramites *ngIf="location === 'Tramites'" [id]="userId" [location]="location" class="w-100"></app-list-tramites>
            </div>
        </div>
    </div>
</div>