import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MensajesService } from '../../service/mensajes.service';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})
export class HeaderTwoComponent implements OnInit {
  currentUser: any = null;
  suscripcionMensajes: any;
  isOpen = false;
  constructor(
    private router: Router,
    private mensajesService: MensajesService
  ) { }

  ngOnInit(): void {
    if (localStorage['currentUser']) {
      this.currentUser = JSON.parse(localStorage['currentUser']);
      const nameWords = this.currentUser.displayName.split(' ');
      let showName = '';
      nameWords.forEach((name) => {
        showName += name[0];
      });
      this.currentUser.initials = showName
    }
    this.escuchaMensajes();
  }

  private escuchaMensajes(): void {
    this.suscripcionMensajes = this.mensajesService.escucha().subscribe(  // Al llamar a escucha() le pasamos el tema que nos interesa
      msj => {
        if (msj !== 'Logout') {
          if (localStorage['currentUser']) {
            this.currentUser = JSON.parse(localStorage['currentUser']);
            const nameWords = this.currentUser.displayName.split(' ');
            this.currentUser.initials = nameWords.map((name) => {
              return name[0];
            })
          }
        } else {
          this.currentUser = null;
        }
      }
    );
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  logout() {
    this.mensajesService.emite('Logout');
    localStorage.removeItem('currentUser');
    this.router.navigateByUrl('/ciudadano/inicio');
    this.ngOnInit();
  }

}
