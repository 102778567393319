import { HttpHeaders } from '@angular/common/http';

export function getHeaders(contentType: string = 'application/json'): HttpHeaders {
  const headers = new HttpHeaders().set('Content-Type', contentType);
  if (localStorage['currentUser']) {
    const user = JSON.parse(localStorage['currentUser']);
    headers.append('Authorization', user.token);
  }
  return headers;
}
