import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@/environments/environment';
import { getHeaders } from '../utils/headers.functions';

const apiUrl = environment.API_URL;

@Injectable({
    providedIn: 'root',
})


export class ApiService {
    updateFile(formData: FormData, id: any) {
        const user = JSON.parse(localStorage['currentUser']);
        return this.http.put<any>(`${apiUrl}/miveracruz/update_requirements_files/${id}/${user.id}`, formData);
    }

    getDocumentsByUserId(id: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiUrl}/miveracruz/citizen_form_request_record/${id}`, { headers });
    }

    getUserData(id: string) {
        const headers= getHeaders();
        return this.http.get<any>(`${apiUrl}/v2/user/${id}`, { headers });
    }

    updateUser(id: string, body: any) {
        const headers = getHeaders();
        return this.http.put<any>(`${apiUrl}/v2/user/${id}`, body, { headers });
    }

    getTramitesByUser(id: string) {
        const headers = getHeaders();
        return this.http.get<any>(`${apiUrl}/miveracruz/citizen_form_request_by_user/${id}`, { headers });
    }

    getSolicitudes(): Observable<any> {
        const headers = getHeaders();
        return this.http.get<any>(`${apiUrl}/miveracruz/citizen_form_request`, { headers });
    }

    getTramitesByArea(areaId: number): Observable<any[]> {
        const headers = getHeaders();
        return this.http.get<any[]>(`${apiUrl}/miveracruz/forms?area=${areaId}`, { headers });
    }

    getAllTramitesFiltered(pageSize: number, pageIndex: number, dependency: any, category: any, format: any) {
        return this.http.get<any>(`${apiUrl}/miveracruz/forms?limit=${pageSize}&offset=${pageIndex}&mode=En Línea&category=${category}&dependency=${dependency}`);
    }

    updateSurveyTramite(body: any): Observable<any> {
        const headers = getHeaders();
        return this.http.put<any>(`${apiUrl}/miveracruz/citizen_form_request`, body, { headers });
    }

    getCatalogInfo(name: string, limit: number, offset: number) {
        return this.http.get<any>(`${apiUrl}/catalogs-data/${name}?limit=${limit}&offset=${offset}`);
    }

    uploadFile(userid: any, documentid: any, name: any, formData: any) {
        return this.http.post<any>(`${apiUrl}/miveracruz/save_requirements_files/${userid}/${documentid}`, formData);
    }

    uploadFileV2(userid: any, requestid: any, documentid: any, name: any, formData: any) {
        return this.http.post<any>(`${apiUrl}/miveracruz/save_requirements_files/${userid}/${requestid}/${documentid}`, formData);
    }

    saveRequest(body: any) {
        const headers = getHeaders();
        return this.http.post<any>(`${apiUrl}/miveracruz/citizen_form_request`, body, { headers });
    }

    constructor(private http: HttpClient) { }

    uploadFielFile(body: FormData){
        return this.http.post<any>(`${apiUrl}/miveracruz/fielFiles`, body);
    }

    getFielFiles(){
        const headers = getHeaders();
        return this.http.get<any>(`${apiUrl}/miveracruz/fielFiles`, { headers });
    }

    updateFielFile(id: number | string, body: FormData){
        const headers = getHeaders('text/plain');
        console.log(`${apiUrl}/miveracruz/fielFiles/${id}`);
        return this.http.put<any>(`${apiUrl}/miveracruz/fielFiles/${id}`, body, { headers });
    }

    getAllTramites() {
        return this.http.get<any>(`${apiUrl}/miveracruz/forms`);
    }

    getAllTramitesPaged(limit: any, offset: any) {
        return this.http.get<any>(`${apiUrl}/miveracruz/forms?limit=${limit}&offset=${offset}`);
    }

    getTramiteFormById(tramiteId: string) {
        return this.http.get<any>(`${apiUrl}/miveracruz/forms/${tramiteId}`);
    }

    getSignatureByPasswd(body: string) {
        const headers = getHeaders();
        return this.http.post<any>(`${apiUrl}/miveracruz/get_signature`, body, { headers });
    }
}