<div class="ml-4">
    <div class="card w-100">
        <div class="card-header d-flex justify-content-between align-items-end mb-4">
            <span class="title">
                Mis Trámites
            </span>
        </div>
        <div class="card-header" style="background-color: #f5f5f5ff; padding: 20px;border-bottom: 0;">
            <!-- Folio del Trámite -->
            <div class="subtitle-section mb-3">
                <span class="subtitle" *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true">
                    {{ tramiteDetails?.Form?.FormsCommon?.Name }}
                </span>

                <div class="loading-placeholder mt-1 mb-2" *ngIf="isLoadingTramite== true && isTramiteLoadedCorrectly== false">
                    <div class="placeholder"></div>
                </div>
            </div>
            <!-- Folio del Trámite -->

            <!-- Detalles del Trámite -->
            <div>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <b>Folio:</b> <br>
                        <span *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true; else LoadingPlaceholder1" class="mt-1 mb-2">
                            {{ tramiteDetails?.CitizenRequest?.Folio }}
                        </span>
                        <ng-template #LoadingPlaceholder1>
                            <div class="loading-placeholder mt-1 mb-2">
                                <div class="placeholder"></div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-md-6">
                        <!-- El campo "Atiende" solo aparece cuando el trámite ya ha sido firmado -->
                        <ng-container *ngIf="currentStatus && currentStatus.Status == 'Finalizado' else TramiteNoFinalizado">
                            <b>Atiende:</b> <br>
                            <span *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true; else LoadingPlaceholder2" class="mt-1 mb-2">
                                ...
                            </span>
                            <ng-template #LoadingPlaceholder2>
                                <div class="loading-placeholder mt-1 mb-2">
                                    <div class="placeholder"></div>
                                </div>
                            </ng-template>
                        </ng-container>
                        <!-- El campo "Atiende" solo aparece cuando el trámite ya ha sido firmado -->

                        <!-- Este campo con nombre "Estatus" aparecerá en esta zona cuando no se haya finalizado el trámite -->
                        <ng-template #TramiteNoFinalizado>
                            <b>Estatus:</b> <br>
                            <span *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true; else LoadingPlaceholder6" class="mt-1 mb-2">
                                {{ currentStatus?.Status }}
                            </span>
                            <ng-template #LoadingPlaceholder6>
                                <div class="loading-placeholder mt-1 mb-2">
                                    <div class="placeholder"></div>
                                </div>
                            </ng-template>
                        </ng-template>
                        <!-- Este campo con nombre "Estatus" aparecerá en esta zona cuando no se haya finalizado el trámite -->
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <b>Dependencia:</b> <br>
                        <span *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true; else LoadingPlaceholder3" class="mt-1 mb-2">
                            Sistema Municipal DIF Veracruz
                        </span>
                        <ng-template #LoadingPlaceholder3>
                            <div class="loading-placeholder mt-1 mb-2">
                                <div class="placeholder"></div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-md-6">
                        <ng-container *ngIf="currentStatus && ['Caducado', 'Rechazado', 'Aprobado', 'Aceptado'].includes(currentStatus.Status)">
                            <b>Fecha de finalización:</b> <br>
                            <span *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true; else LoadingPlaceholder4" class="mt-1 mb-2">
                                ...
                            </span>
                            <ng-template #LoadingPlaceholder4>
                                <div class="loading-placeholder mt-1 mb-2">
                                    <div class="placeholder"></div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <b>Área:</b> <br>
                        <span *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true; else LoadingPlaceholder5" class="mt-1 mb-2">
                            {{ tramiteDetails?.Form?.Areas?.Name }}
                        </span>
                        <ng-template #LoadingPlaceholder5>
                            <div class="loading-placeholder mt-1 mb-2">
                                <div class="placeholder"></div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-md-6">
                        <!-- Este campo con nombre "Estatus" se mostrará en esta zona cuando el trámite haya sido Finalizado -->
                        <ng-container *ngIf="currentStatus && currentStatus.Status == 'Finalizado'">
                            <b>Estatus:</b> <br>
                            <span *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true; else LoadingPlaceholder6" class="mt-1 mb-2">
                                {{ currentStatus?.Status }}
                            </span>
                            <ng-template #LoadingPlaceholder6>
                                <div class="loading-placeholder mt-1 mb-2">
                                    <div class="placeholder"></div>
                                </div>
                            </ng-template>
                        </ng-container>
                        <!-- Este campo con nombre "Estatus" se mostrará en esta zona cuando el trámite haya sido Finalizado -->
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6">
                        <b>Fecha de Solicitud:</b> <br>
                        <span *ngIf="isLoadingTramite== false && isTramiteLoadedCorrectly== true; else LoadingPlaceholder7" class="mt-1 mb-2">
                            {{ tramiteDetails?.CitizenRequest?.created_at | date:'yyyy-MM-dd HH:mm:ss' }}
                        </span>
                        <ng-template #LoadingPlaceholder7>
                            <div class="loading-placeholder mt-1 mb-2">
                                <div class="placeholder"></div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="col-md-6"></div>
                </div>

                <!-- Botones de acciones de detalle del trámite -->
                <div class="mt-4">
                    <div class="d-flex justify-content-end">
                        <button class="btn recibo">
                            Recibo de Pago <i class="fa fa-download" aria-hidden="true"></i>
                        </button>
                        <button class="btn protesta">
                            <span style="vertical-align: middle">Protesta Ciudadana</span>
                            <img src="assets/images/exclamacion-protesta(2).png" class="first-image">
                            <img src="assets/images/exclamacion-protesta(1).png" class="second-image">
                        </button>
                        <button class="btn historial">
                            Ver Historial <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <!-- Botones de acciones de detalle del trámite -->
            </div>
            <!-- Detalles del Trámite -->
        </div>
        <div class="card-body p-0">
            <div class="pt-5">
                <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" class="custom-tab-group">
                    <mat-tab label="Archivos" class="tab">
                        <div class="contenedor-tabla-docs mt-4">
                            <table class="table w-100 tabla-documentos">
                                <thead>
                                    <tr>
                                        <th>Documentos a revisar:</th>
                                        <th></th>
                                        <th>Estatus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="tramiteDetails && tramiteDetails.CitizenRequirements && (esArreglo(tramiteDetails.CitizenRequirements) && tramiteDetails.CitizenRequirements.length > 0)">
                                        <tr *ngFor="let documento of tramiteDetails.CitizenRequirements; let i = index">
                                            <td>
                                                <b>{{ i+1 }}.</b> {{ documento.Requirements.Name }}
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </mat-tab>
                    <mat-tab label="Datos del ciudadano" class="tab">
                        <div class="pt-4 pb-3">
                            <b class="form-generales" style="font-weight:bold;font-size:110%">Persona física</b> <br>
        
                            <div class="row mt-3">
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Nombre(s)</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.User?.firstName }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Apellido Paterno</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.User?.lastNames[0] }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Apellido Materno</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.User?.lastNames[1] }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Fecha de nacimiento</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.User?.birthday }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">RFC</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Person?.TaxId }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Teléfono celular</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Person?.MobilePhone }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Teléfono particular</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Person?.OfficePhone }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Calle</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Address?.Street }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Número exterior</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Address?.ExtNumber }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Número interior</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Address?.IntNumber }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Colonia</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Address?.Neighborhood }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Código postal</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Address?.ZipCode }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Referencias</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Address?.Notes }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Estado</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Address?.State }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-container-resumen">
                                        <label class="form-generales">Municipio</label>
                                        <div class="form-text-data">
                                            <p class="input e-generales">
                                                {{ ciudadanoDetails?.Address?.City }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Formulario" class="tab">
                        ...
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>