<div class="container-primary">
    <div class="container">
        <div id="header" class="row py-1">
            <div class="col-12 col-md-2 position-relative">
                <a [routerLink]="['/']">
                    <img src="../../../../assets/images/miveracruz/logo.png" width="120px" alt="">
                </a>
            </div>
            <div class="col-12 col-md-6 d-flex align-items-center">
                <ul id="menu" class="d-flex justify-content-center">
                    <li><a [routerLink]="['/ciudadano/tramites']">Trámites</a></li>
                    <li><a [routerLink]="['/ciudadano/servicios']">Servicios</a></li>
                    <li><a [routerLink]="['/ciudadano/empleos']">Empleos</a></li>
                </ul>
            </div>
            <div class="col-12 col-md-4 d-flex justify-content-end">
                <ul class="d-flex align-items-center" *ngIf="!currentUser">
                    <li class="mr-3">
                        <a [routerLink]="['/ciudadano/auth/login']" class="download">Iniciar sesión</a>
                    </li>
                    <li><a [routerLink]="['/ciudadano/auth/register']" class="download">Regístrate</a></li>
                </ul>
                <!-- <ul class="menu-right d-flex align-self-center align-items-center" *ngIf="currentUser">
                    <li class="menu-name">{{ currentUser.initials }}</li>
                    <li class="menu-dropdown">
                        <i class="fa fa-chevron-down"></i>
                        <ul>
                            <li><a href="">Cerrar sesión</a></li>
                        </ul>
                    </li>
                </ul> -->
                <ul *ngIf="currentUser" class="d-flex align-items-center nav-menus">
                    <!--  <div class="dropdown">
                        <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            D,T,R
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" href="#">Ver perfil</a>
                            <a class="dropdown-item" href="#">Cerrar sesión</a>
                        </div>
                    </div> -->
                    <li (click)="toggleDropdown()">
                        <div class="media align-items-center">
                            <span class="name pdd-right-5 menu-name">{{currentUser.initials}}</span>
                            <span class="menu-dropdown"><i class="fa fa-chevron-down"></i></span>
                        </div>
                        <ul style="width: 150px;" *ngIf="isOpen"
                            class="profile-dropdown p-10 profile-dropdown-hover bg-white mt-1">
                            <li>
                                <a *ngIf="currentUser?.roles !== 'admin'"
                                    [routerLink]="['/ciudadano/profile/', currentUser?.id]" title="Editar usuario">
                                    Ver perfil
                                </a>
                            </li>
                            <li (click)="logout()" style="cursor: pointer">
                                <a class="text-primary"> Cerrar sesión </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>