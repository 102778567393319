<ng2-toasty></ng2-toasty>

<!-- Container-fluid starts-->
<div class="container-fluid">
    <div id="batchDelete" class="">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <button class="card-header custom-card-header d-flex align-items-center" (click)="togglePanel()">
                        <h2 style="margin-left: 10px">{{ nombretramite }}</h2>
                        <!-- Ajusta el valor del margen según sea necesario -->
                        <span class="arrow-icon"
                            [ngClass]="{ 'arrow-down': !isExpanded, 'arrow-up': isExpanded }"></span>
                    </button>

                    <div class="card-body ficha" *ngIf="isExpanded">
                        <div class="row">
                            <div class="col-md-3">
                                <b>Folio:</b><br>
                                <label>{{ folio }}</label><br>
                                <b>Dependencia:</b><br>
                                <label>{{ dependencia }}</label><br>
                                <b>Área:</b><br>
                                <label>{{ area }}</label><br>
                            </div>
                            <div class="col-md-3">
                                <b>Fecha de solicitud:</b><br>
                                <label>{{ fechacreacion }}</label><br>
                                <label class="card-text-fecha">
                                    <b>Estatus:</b><br>
                                    <span class="badge badge-primary" id="ver_historial">{{ status }}</span>
                                    <br>
                                    <span class="text-small font-weight-bold">
                                        <span></span>
                                    </span>
                                </label>
                            </div>
                            <div class="col-md-3">
                                <ng-container *ngIf="status == 'Finalizado'">
                                    <b>Atiende:</b><br>
                                    <label>{{ atendidoPor }}</label><br>
                                </ng-container>
                                <b>Fecha de última actualización:</b><br>
                                <label>{{ fechaupdate }}</label><br>
                            </div>
                            <div class="col-md-3">
                                <div class="mt-3">
                                    <button class="btn btn-primary mb-1 btn-block"
                                            role="button" 
                                            aria-disabled="true"
                                            id="historial"
                                            (click)="openModal(verHistorialTramite)"
                                            [disabled]="historial == null"
                                    >
                                                <i class="fa fa-history" aria-hidden="true"></i>VER HISTORIAL <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                    <button class="btn btn-primary mr-4 btn-block" role="button" aria-disabled="true"
                                        disabled="" id="llenar_formato">
                                        FORMATO <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="scrolling-div ">
            <div class="button-container">
                <button class="button rechazar" 
                        [ngClass]="{ 'disabled': (status != 'Pendiente de firma' && status != 'Prevención respondida' && status != 'Sin iniciar' && status != 'En revisión') && isButtonsDisabled }" 
                        role="button" 
                        aria-disabled="true" 
                        id="rechazar"
                        [disabled]="status != 'Pendiente de firma' && isButtonsDisabled" 
                        (click)="openRejectRequest(rejectRequest)"
                >
                    RECHAZAR <i class="fa fa-times-circle mr-0" aria-hidden="true"></i>
                </button>

                <ng-container *ngIf="isPrevencionRespondida == true || ['Aceptado','Rechazado','Pendiente de firma','Finalizado','Caducado','Pagado','Sin iniciar'].includes(status)">
                    <button class="button prevenir" 
                            [ngClass]="{ 'disabled': ( (isButtonsDisabled || isPrevencionRespondida) || isAcceptAndContinueDisabled == false ) }" 
                            role="button" 
                            aria-disabled="true" 
                            id="prevenir"
                            [disabled]="(isButtonsDisabled || isAcceptAndContinueDisabled == false)" 
                            (click)="openPrevencionRequest(prevencionRequest)"
                    >
                        PREVENIR AL CIUDADANO <i class="fa fa-exclamation" aria-hidden="true"></i>
                    </button>
                </ng-container>
                
                <!-- Botón para Aceptar Trámite y proceder con Pago -->
                <ng-container *ngIf="isTramitePagado == false && isTramitePendientePago == false">
                    <button class="button aceptar_continuar" 
                            [ngClass]="{ 'disabled': (isButtonsDisabled || isAcceptAndContinueDisabled) && isPrevencionRespondida == false }" 
                            role="button" 
                            aria-disabled="true" 
                            id="aceptar_continuar"
                            [disabled]="(isButtonsDisabled || isAcceptAndContinueDisabled) && isPrevencionRespondida == false"
                            (click)="updateStatus('Aceptado')"
                    >
                        ACEPTAR Y CONTINUAR <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                </ng-container>
                <!-- Botón para Aceptar Trámite y proceder con Pago -->

                <!-- Botón para proceder con la autorización -->
                <ng-container *ngIf="isTramitePagado == true || isTramitePendientePago == true">
                    <button class="button autorizar" 
                            [ngClass]="{ 'disabled': isTramitePendientePago }" 
                            [disabled]="isTramitePendientePago == true"
                            (click)="openModal(observacionesFormatoFinal)"
                    >
                        AUTORIZAR <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                </ng-container>
                <!-- ./Botón para proceder con la autorización -->
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="row monitor-container" [ngClass]="{ loadingDocument: isCursorLoading }">
                    <div class="mt-3 col-lg-5 col-sm-12">
                        <div class="pdf-container">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        <h3 class="text-primary">
                                            <b>{{ showDocumento?.index }}.</b>
                                            {{ showDocumento?.Name }}
                                            <i class="fa fa-download ml-2" (click)="openNewDocument()"></i>
                                        </h3>
                                    </div>
                                    <div class="col-12 col-md-4 d-flex justify-content-end">
                                        <b class="text-primary">
                                            Archivo {{ showDocumento?.index }} de {{ documentos.length }}
                                        </b>
                                    </div>
                                </div>
                            </div>
                            <div id="documentViewer"></div>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <button class="download text-center w-100" (click)="previousDocument()">
                                            <img src="../../../../assets/images/miveracruz/next.png" alt=""
                                                style="transform: rotate(180deg);">
                                        </button>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <button class="download text-center w-100" (click)="nextDocument();">
                                            <img src="../../../../assets/images/miveracruz/next.png" alt="">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3 col-lg-7 col-sm-12 ancho-extra">
                        <ng-container *ngIf="status == 'Rechazado'">
                            <div class="alert" style="border-radius: 0;background-color: #fad2d2ff;" role="alert">
                                <h3 style="
                                        text-transform: uppercase;
                                        text-align: center;
                                        font-weight: bold;
                                        color: #87273fff;
                                        text-decoration: underline;
                                        margin-bottom: 4px;"
                                >
                                    Trámite Rechazado
                                </h3>

                                <p style="
                                        color: #87273fff;
                                        font-weight: 600;
                                        font-size: 102%;
                                        text-align: left;"
                                >
                                    Después de prevenir al ciudadano, este no corrigió sus archivos o formulario conforme a lo solicitado, por lo tanto,
                                    no se pudo cotejar la información necesaria para continuar el trámite.
                                </p>
                            </div>
                        </ng-container>

                        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" class="custom-tab-group">

                            <mat-tab label="Documentos">
                                <!-- <div class="documentos-example-large-box mat-elevation-z4"> -->
                                <div class="col-md-12 mt-3">
                                    <div *ngIf="documentos && documentos.length > 0">
                                        <div class="table-container">
                                            <table class="mat-table full-width-table">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 75%;">Documentos a revisar:</th>
                                                        <th style="width: 4%;"></th>
                                                        <th style="width: 10%;">Aceptado</th>
                                                        <th style="width: 10%;">Rechazado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let documento of documentos; let i = index">
                                                        <td>
                                                            <span
                                                                [ngClass]="{ 'font-weight-bold' : showDocumento?.index === documento?.index }">
                                                                {{ documento?.index }}. <a href="javascript:void(0)"
                                                                    (click)="seleccionarDocumento(documento)">{{ documento.Name }}</a>
                                                            </span>
                                                        </td>
                                                        <td class="comentarios-cell"
                                                            [ngClass]="{
                                                                'button-cell' : documento.Comments && documento.Comments !== 'Null',
                                                                'disabled': !documento.Comments || documento.Comments === 'Null'
                                                            }"
                                                        >
                                                            <ng-container *ngIf="documento.Comments && documento.Comments !== 'Null' ">
                                                                <div
                                                                    (click)="openCommentsDoc(commentsDocRequest, documento, (i+1))"
                                                                    [ngClass]="{'div-disabled': !documento.Comments || documento.Comments === 'Null'}"
                                                                >
                                                                    <i class="fa fa-commenting mr-0" aria-hidden="true"></i>
                                                                </div>
                                                            </ng-container>
                                                        </td>
                                                        <td class="button-cell aceptar-cell"
                                                            (click)="approveDocument(documento, (i+1))"
                                                            [ngClass]="{ 'approved': documento.status, 'disabled': documento.status === false, loadingDocument: isCursorLoading }">
                                                            <i class="fa fa-check mr-0" aria-hidden="true"></i>
                                                        </td>
                                                        <td class="button-cell rechazar-cell"
                                                            [ngClass]="{ 'reject': documento.status === false, 'disabled': documento.status === true, loadingDocument: isCursorLoading }"
                                                            (click)="openReject(rejectDocument, documento, (i+1))">
                                                            <i class="fa fa-times mr-0" aria-hidden="true"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </mat-tab>

                            <mat-tab label="Datos Generales">
                                <!-- <div class="example-large-box mat-elevation-z4"> -->
                                <form class="formulario" id="formulario_dg" onsubmit="return false;">
                                    <div class="form-resumen-tramite">
                                        <div class="col-md-12">
                                            <div class="row mt-3">
                                                <div class="form-group col-md-12 mb-2">
                                                    <div
                                                        class="custom-radio form-check form-check-inline form-check-resumen">
                                                        <input class="form-check-input" type="radio" name="tipoPersona"
                                                            [value]="'Persona Física'"
                                                            [checked]="selectedOption === 'Física'" disabled>
                                                        <label class="form-check-label mb-0">Persona física</label>
                                                    </div>
                                                    <div class="custom-radio form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="tipoPersona"
                                                            [value]="'Persona moral'"
                                                            [checked]="selectedOption === 'Moral'" disabled>
                                                        <label class="form-check-label mb-0">Persona moral</label>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Nombre(s)</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ nombre }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Apellido Paterno</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ apellidoPaterno }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Apellido Materno</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ apellidoMaterno }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">CURP</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ userDetails?.Person?.CURP }}</p>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Correo Electrónico</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ userDetails?.User?.email }}</p>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Fecha de nacimiento</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ fecha_nacimiento }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">RFC</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ rfc }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Teléfono celular</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ celular }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Teléfono particular</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ telefono }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Calle</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ calle }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Número ext</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ numExt }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Número int</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ numInt }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Colonia</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ colonia }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Código Postal</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ CodPos }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Municipio</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ municipio }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Estado</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ estado }}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="form-container-resumen">
                                                        <label class="form-generales">Referencias</label>
                                                        <div class="form-text-data">
                                                            <p class="input e-generales">{{ referencias }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!-- </div> -->
                            </mat-tab>

                            <mat-tab label="Datos del formulario">
                                <!-- <div class="example-large-box mat-elevation-z4"> -->
                                <form class="formulario" id="formulario_pf" (submit)="onSubmit()">
                                    <div class="form-resumen-tramite" style="max-height: 500px; overflow-y: auto;">
                                        <div class="mt-3">
                                            <div class="col-md-12">
                                                <!-- Iterar sobre las secciones -->
                                                <div *ngFor="let section of citizenSurveys; let i = index;">
                                                    <div class="row">
                                                        <div class="col-12 col-md-8">
                                                            <h2 style="color: #033951; font-weight: 600;">{{ section?.Section }}</h2>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-3">
                                                        <div class="col-md-4" *ngFor="let survey of section.Survey">
                                                            <div class="form-container-resumen">
                                                                <label class="form-generales">{{
                                                                    survey.Question}}</label>
                                                                <div class="form-text-data">
                                                                    <p class="input e-generales">{{ survey.Answer }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 comment-document d-flex justify-content-between"
                                                            *ngIf="section.comments">
                                                            {{ section.comments }}
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <!-- Agregando un salto de línea después de cada sección -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!-- </div> -->
                                <hr>

                                <!-- Contenedor: Aparece cuando se han rechazado los Datos del formulario -->
                                <div class="container-fluid" *ngIf="mostrarCampoDatosIncorrectos === false && surveyComment?.length">
                                    <div class="row mb-3">
                                        <div class="col-7">
                                            <p class="text-primary" style="font-weight:bolder">
                                                Se le informó al ciudadano lo siguiente:
                                            </p>
                                        </div>
                                        <div class="col-5 text-right" *ngIf="isEditableSurveyComment">
                                            <div class="row">
                                                <div class="col-6">
                                                    <!--  Botón Refrescar -->
                                                    <button class="btn btn-link p-0 m-0 btn-editable" (click)="undoSurveyComment()">
                                                        <i class="fa fa-refresh" aria-hidden="true"></i>&#160;
                                                        <small>Deshacer Cambios</small>
                                                    </button>
                                                </div>
                                                <div class="col-6">
                                                    <!-- Botón Editar -->
                                                    <button class="btn btn-link p-0 m-0 btn-editable" (click)="editCommentSurvey(rejectedForm)">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>&#160;
                                                        <small>Editar Comentario</small>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="alert alert-light d-flex align-items-center" style="padding: 0rem 0.225rem" role="alert">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                            <div>
                                                {{ surveyComment }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ./Contenedor -->


                                <!-- Contenedor: Aparece cuando se ha aprovado los Datos del formulario -->
                                <div class="container-fluid" *ngIf="(mostrarCampoDatosIncorrectos === false && !surveyComment?.length) && (status == 'Sin iniciar' || status == 'En revisión' || status == 'En proceso')">
                                    <div class="row mb-3">
                                        <div class="col-9">
                                            <p class="text-primary" style="font-weight:bolder">
                                                Se aceptaron los datos del formulario.
                                            </p>
                                        </div>
                                        <div class="col-3 text-right">
                                            <!--  Botón Deshacer cambios -->
                                            <button class="btn btn-link p-0 m-0 btn-editable" (click)="undoSurveyComment()">
                                                <i class="fa fa-refresh" aria-hidden="true"></i>&#160;
                                                <small>Deshacer Cambios</small>
                                            </button>
                                            <!--  Botón Deshacer cambios -->
                                        </div>
                                    </div>
                                </div>
                                <!-- ./Contenedor -->


                                <!-- Contenedor: Aparece cuando no se ha evaluado los Datos del formulario -->
                                <div class="formulario d-flex" *ngIf="mostrarCampoDatosIncorrectos && isDocumentsReviewed && !isPrevencionRespondida">
                                    <div class="col-12 col-md-8">
                                        <label style="color: #033951; font-weight: 600;">¿Los datos son correctos?</label>
                                    </div>
                                    <div class="col-12 col-md-4 d-flex">
                                        <button class="btn btn-sm btn-success mr-3"
                                            (click)="confirmCorrectData($event, confirmCorrectDataForm)"
                                            style="display:flex; justify-content: center; align-items: center; height: 35px;"
                                        >
                                            <i class="fa fa-check mr-0"></i>
                                        </button>
                                        <button class="btn btn-sm btn-danger mr-3" (click)="rejectForm(rejectedForm)"
                                            style="display:flex; justify-content: center; align-items: center; height: 35px;"
                                        >
                                            <i class="fa fa-times mr-0" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- ./Contenedor -->
                            </mat-tab>

                        </mat-tab-group>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #rejectDocument let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="row text-center">
                    <b class="text-primary text-center">Documento rechazado</b>
                </div>
                <div class="row pt-3">
                    <div class="col-12 col-md-10 bg-light py-2 text-primary">
                        <b>{{ documentSelect.index }}.</b> {{ documentSelect.Name }}
                    </div>
                    <div class="col-12 col-md-2 text-center bg-danger py-2">
                        <i class="fa fa-times mr-0" aria-hidden="true"></i>
                    </div>
                    <div class="col-12 py-3">
                        <span class="mb-2 text-primary">Retroalimentación para el solicitante</span>
                        <textarea class="form-control w-100" name="dSC" [(ngModel)]="documentSelect.Comments" (keyup)="limit150($event)"></textarea>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
                        <b>{{ documentSelect.Comments.length }}/150 CARACTERES</b>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                        <button class="btn btn-sm btn-danger mr-3" (click)="cancelReject()">Cancelar</button>
                        <button class="btn btn-sm btn-success" (click)="documentSelect.Comments == '' ? documentSelect.status = null : null; closeModal()">Guardar</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-template>

<ng-template #rejectedForm let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="cuadroprenvencion">
                    <i class="fa fa-exclamation" aria-hidden="true"></i>
                </div>
                <div class="text-center">
                    <b class="text-primary prevencion-titulo">Datos incorrectos</b>
                </div>
                <div class="row pt-3">
                    <div class="col-12 py-3">
                        <label class="mb-2 text-primary prevencion-modal">Describe la corrección que debe realizar el
                            ciudadano:</label>
                        <textarea class="form-control w-100" name="sC" [(ngModel)]="surveyComment" (keyup)="limit300($event)"></textarea>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
                        <b>{{ surveyComment.length }}/300 CARACTERES</b>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                        <button (click)="surveyComment = auxSurveyComment; closeModal()" class="btn btn-sm cancelar mr-3">Cancelar</button>
                        <button (click)="saveIncorrectDataComments($event)" class="btn btn-sm prevenirmodal mr-3">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #prevencionRequest let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="cuadroprenvencion">
                    <i class="fa fa-exclamation" aria-hidden="true"></i>
                </div>
                <div class="text-center">
                    <b class="text-primary prevencion-titulo">Prevención al ciudadano</b>
                </div>
                <div class="row pt-3">
                    <div class="col-12 py-3">
                        <label class="text-primary prevencion-modal">Tiempo límite de respuesta de parte del ciudadano:</label>&nbsp;<label
                            class="text-primary prevencion-modal-hora"> 72 horas</label><br>
                        <br><label class="mb-2 text-primary prevencion-modal">Descripción de la prevención al
                            ciudadano:</label>
                        <textarea class="form-control w-100" name="c" [(ngModel)]="comments" (keyup)="limit300($event)"></textarea>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
                        <b>{{ comments.length }}/300 CARACTERES</b>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                        <button (click)="closeModal()" class="btn btn-sm cancelar mr-3">Cancelar</button>
                        <button (click)="modalValidarPrevencion(validarContraPrevencion)" class="btn btn-sm prevenirmodal mr-3">Prevenir</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #validarContraPrevencion let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="cuadroprenvencion">
                    <i class="fa fa-lock" aria-hidden="true"></i>
                </div>
                <div class="text-center">
                    <b class="text-primary prevencion-titulo">Finalizar Prevención</b>
                </div>
                <div class="row pt-3">
                    <div class="col-12 py-3">
                        <span class="text-primary prevencion-modal mb-3">Ingrese su contraseña:</span> <br><br>
                        <input type="password" class="form-control w-100" #passwdAsesorF/> <br>
                        <small>Su contraseña es importante para continuar con el proceso de prevención.</small>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-sm atras mr-3" (click)="_modalValidarPrevencion.close(); _modalValidarPrevencion = null;">Atrás</button>
                        <button class="btn btn-sm prevenirmodal mr-3" (click)="validarPrevencionContra(passwdAsesorF)">Continuar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #rejectRequest let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="cuadrorechazar">
                    <i class="fa fa-window-close" aria-hidden="true"></i>
                </div>
                <div class="text-center">
                    <b class="text-primary prevencion-titulo">Rechazar solicitud</b>
                </div>
                <div class="row pt-3">
                    <div class="col-12 py-3">
                        <label class="mb-2 text-primary rechazar-modal" for="c2">Retroalimentación para el solicitante:</label>
                        <textarea class="form-control w-100" name="c2" [(ngModel)]="comments" (keyup)="limit150($event)"></textarea>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end align-items-center">
                        <b>{{ comments.length }}/150 CARACTERES</b>
                    </div>
                    <div class="col-12 col-md-6 d-flex justify-content-end">
                        <button (click)="closeModal()" class="btn btn-sm cancelar mr-3">Cancelar</button>
                        <button (click)="updateStatus('Rechazado')" class="btn btn-sm rechazarmodal mr-3">Rechazar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #commentsDocRequest let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <b class="text-primary">Comentarios del documento</b>
                </div>
                <div class="row pt-3">
                    <div class="col-12 col-md-10 bg-light py-2 text-primary">
                        <b>{{ documentSelect.index }}.</b> {{ documentSelect.Name }}
                    </div>
                    <div class="col-12 col-md-2 text-center py-2" style="background-color: #e4e4e4;">
                        <i class="fa fa-commenting mr-0" aria-hidden="true"></i>
                    </div>
                    <div class="col-12 py-3">
                        <p class="mb-2 text-primary">Retroalimentación del solicitante</p>
                        <p>{{ commentsAdded }}</p>
                    </div>
                    <div class="col-12 mt-3 d-flex justify-content-end">
                        <button (click)="closeModal()" class="btn btn-sm btn-secondary mr-3">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #confirmCorrectDataForm let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="row">
                    <b class="text-primary fs-1">Confirmar Cambios</b>
                </div>
                <div class="row pt-3">
                    <div class="col-12">
                        <p class="text-primary">¿Está seguro que los datos del formulario son correctos?</p>
                    </div>
                    <div class="col-12 mt-3 d-flex justify-content-end">
                        <button (click)="closeModal()" class="btn btn-sm btn-secondary mr-3">Cancelar</button>
                        <button (click)="confirmFormData()" class="btn btn-sm btn-success mr-3">Continuar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Modal para agregar observaciones al formato final -->
<ng-template #observacionesFormatoFinal let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="cuadro autorizar">
                    <i class="fa fa-info" aria-hidden="true"></i>
                </div>
                <div class="text-center">
                    <b class="text-primary prevencion-titulo">Observaciones</b>
                </div>
                <div class="row pt-3">
                    <div class="col-12 py-3">
                        <label class="mb-3 text-primary prevencion-modal">Agrega tus observaciones al formato final:</label>
                        <textarea class="form-control w-100" name="cFF" [(ngModel)]="comentariosFormatoFinal" (keyup)="limit300($event)"></textarea>
                    </div>
                    <div class="col-12 col-md-6 d-flex">
                        <b>{{ comentariosFormatoFinal.length }}/300 CARACTERES</b>
                    </div>
                </div>
                <div class="row pt-3 d-flex justify-content-center">
                    <button class="btn btn-sm infomodal">Previsualizar</button>
                    <button (click)="closeModal()" class="btn btn-sm cancelar mr-2 ml-2">Cancelar</button>
                    <button class="btn btn-sm continuarmodal" (click)="openFielModal(autorizarTramite)">Continuar a Firma</button>
                </div>
                <div class="container mt-4">
                    <div class="row justify-content-center">
                        <i class="fa fa-circle" aria-hidden="true"></i>
                        <i class="fa fa-circle-thin" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Modal para agregar observaciones al formato final -->

<!-- Modal para realizar firma de tramite -->
<ng-template #autorizarTramite let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="cuadro autorizar">
                    <i class="fa fa-check" aria-hidden="true"></i>
                </div>
                <div class="text-center">
                    <b class="text-primary prevencion-titulo">Trámite listo para autorizar</b> <br>
                    <label class="mb-3 text-primary prevencion-modal mt-2">El documento a aprobar es el siguiente:</label>
                </div>
                <div class="text-center">
                    <a (click)="emitirComprobanteTramite()" class="btn btn-link" id="link-comprobante-tramite">
                        <span style="font-size:32px"> <i class="fa fa-file-text-o" aria-hidden="true"></i> </span> <br><br>
                        <b style="text-transform: none">{{ tramiteDetails?.Form?.FormsCommon?.Name }}.pdf</b>
                    </a>
                </div>
                <div class="row pt-3">
                    <div class="col-12 py-3">
                        <label class="mb-3 text-primary prevencion-modal">Para completar la autorización, por favor, <b>ingresa la contraseña de tu FIEL:</b></label>
                        <div class="container">
                            <label class="mb-3 text-primary prevencion-modal" style="font-weight: bold">Contraseña de FIEL:</label>
                            <input class="form-control w-100" id="passwdfiel" type="password" (keyup)="limit300($event)" [(ngModel)]="passwdFirma" [ngClass]="{'disabled': signatureDetails && signatureDetails != null}">
                        </div>
                    </div>
                </div>
                <div class="row pt-3 d-flex justify-content-end">
                    <button (click)="signatureDetails && signatureDetails != finalizarFirmado() ? closeFielModal() : closeModal()" 
                            class="btn btn-sm cancelar mr-2 ml-2" 
                            [ngClass]="{'signed': signatureDetails && signatureDetails != null}" 
                            style="padding:11px 54px"
                    >
                        {{ signatureDetails && signatureDetails != null ? 'Cerrar' : 'Cancelar' }}
                    </button>
                    <button class="btn btn-sm continuarmodal" [ngClass]="{'disabled': signatureDetails && signatureDetails != null}" style="padding:11px 54px" (click)="verificarFirmaElectronica()">Firmar</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Modal para realizar firma de tramite -->

<!-- Modal para visualizar el historial del trámite -->
 <ng-template #verHistorialTramite let-modal>
    <div class="card container mb-0">
        <div class="card-body">
            <div class="container">
                <div class="text-center mt-4">
                    <b class="text-primary prevencion-titulo">Historial del trámite</b>
                </div>

                <div class="mt-3">
                    
                    <table class="mx-auto table w-75">
                        <tr>
                            <td class="border-0">
                                <i class="fa fa-circle" aria-hidden="true" style="color:#00b579ff"></i>
                                <span class="text-primary">Acción del solicitante</span>
                            </td>
                            <td class="border-0">
                                <i class="fa fa-circle" aria-hidden="true" style="color:#2586baff"></i>
                                <span class="text-primary">Acción del asesor</span>
                            </td>
                        </tr>
                    </table>
                    
                    <div class="pl-5 pr-5 mb-4">
                        <ul class="list-group historial-tramite">
                            <!-- Se itera cada elemento del historial -->
                            <ng-container *ngFor="let historia of historial; let i = index;">
                                <li class="list-group-item">
                                    <i class="fa fa-circle" [ngClass]="{'accion-asesor': historia.UserType == 'Funcionario', 'accion-solicitante': historia.UserType == 'Ciudadano'}" aria-hidden="true"></i>
                                    
                                    <b class="text-primary">{{ historia.Activity }}</b> <br>
                                    <span class="text-primary">{{ historia.fechaFormateada }} <b>|</b> {{ historia.horaFormateada }}</span> <br>
                                    <span class="text-primary">{{ historia.ActivityBy }}</span>
                                </li>
                            </ng-container>
                            <!-- Se itera cada elemento del historial -->
                        </ul>
                    </div>

                    <div class="text-center">
                        <button class="btn aceptar_global" (click)="closeModal()">OK</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
 </ng-template>
<!-- Modal para visualizar el historial del trámite -->