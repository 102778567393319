import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ApiService } from '@/app/shared/service/api.service';
import { Subscription } from 'rxjs';
import { FielData, FielModel } from './data.models';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})

export class DataComponent implements OnInit {

  @Input() id: string;

  onEdit = false;
  currentUser: any;
  profile: any = { person: null };
  user: any;
  dadName = "";
  momName = "";
  address: any;

  private subs$: Subscription[]= [];
  fielFiles: any[]= [];

  fechaUltimaVez: any = {
    'cer': '',
    'key': ''
  };

  validCerFileTypes: any = [
    'application/x-x509-ca-cert',
    'application/x-cert',
    'application/pkix-cert'
  ];

  isCerLoaded: boolean = false;
  isKeyLoaded: boolean = false;

  buttons: HTMLButtonElement[] = [];

  constructor(
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private apiService: ApiService
  ) {

    this.toastyConfig.theme = 'bootstrap';
    this.currentUser = this.authService.currentUserValue;

    if (localStorage['currentUser']) {
      const user = JSON.parse(localStorage['currentUser']);

      this.subs$.push(
        this.authService.getUserV2(user.id)
        .subscribe(
          (user) => {
            this.profile = user;
            if (user.Address) {
              this.address = user.Address;
            } else {
              this.address = {
                ZipCode: '',
                City: '',
                State: '',
                Neighborhood: '',
                Street: '',
                ExtNumber: '',
                IntNumber: '',
                Notes: '',
              }
            }

            this.dadName = user.User.lastName.split(" ")[0];
            this.momName = user.User.lastName.split(" ")[1];
        },
        (error) => console.log(error))
      );
    }

  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    if(this.currentUser?.roles == 'admin'){

      this.buttons= [
        document.getElementById('btnLoadCer') as HTMLButtonElement,
        document.getElementById('btnLoadKey') as HTMLButtonElement
      ];

      if (this.fielFiles.length == 0) {
        try{
          this.fielFiles = await this.obtenerArchivosFiel();
          console.log('response: ', this.fielFiles);

          if(this.fielFiles && Array.isArray(this.fielFiles) && this.fielFiles.length > 0 && !this.fielFiles.includes(null)){
            const validatedFF = this.validateFiles(this.fielFiles);
            console.log('validatedFF: ',validatedFF);

            if(validatedFF.cerFile.file && validatedFF.cerFile.isValid == true){
              // Actualizar la fecha de carga del cer
              this.fechaUltimaVez.cer = this.tiempoDesde(new Date()); // Colocar la fecha real en que se cargó el archivo 'cer'
              this.isCerLoaded = true;
            }
            if(validatedFF.keyFile.file && validatedFF.keyFile.isValid == true){
              // Actualizar la fecha de carga del key
              this.fechaUltimaVez.key = this.tiempoDesde(new Date()); // Colocar la fecha real en que se cargó el archivo 'key'
              this.isKeyLoaded = true;
            }
          }

          // Habilitar los botones para su carga o remplazo de archivos
          for(let button of this.buttons){
            button.removeAttribute('disabled');
          }
          // Ocultar spinner de proceso de carga
          for(let spinner of [
            document.getElementsByClassName('cargandoFiel')[0] as HTMLSpanElement,
            document.getElementsByClassName('cargandoFiel')[1] as HTMLSpanElement
          ]){
            spinner.style.display= 'none';
          }

        }catch(exception){
          console.error(exception.error.stack ?? exception.error);
        }
      }
    }
  }

  tiempoDesde(fp) {
    const fechaActual = new Date();
    const fechaPasada = new Date(fp);
    const diferenciaSegundos = Math.floor((fechaActual.getTime() - fechaPasada.getTime())/1000);

    if (diferenciaSegundos < 60) {
      return `hace ${diferenciaSegundos} segundo${diferenciaSegundos !== 1 ? 's' : ''}`;
    } else if (diferenciaSegundos < 3600) {
      const minutos = Math.floor(diferenciaSegundos / 60);
      return `hace ${minutos} minuto${minutos !== 1 ? 's' : ''}`;
    } else if (diferenciaSegundos < 86400) {
      const horas = Math.floor(diferenciaSegundos / 3600);
      return `hace ${horas} hora${horas !== 1 ? 's' : ''}`;
    } else {
      const dias = Math.floor(diferenciaSegundos / 86400);
      return `hace ${dias} día${dias !== 1 ? 's' : ''}`;
    }
  }

  ngOnDestroy(): void {
    if (this.subs$ && this.subs$.length > 0) {
      this.subs$.forEach((s: Subscription) => s.unsubscribe());
    }
  }

  async obtenerArchivosFiel() {
    var aux: FielModel[] = [null, null];
    try {
      aux = await this.apiService.getFielFiles().toPromise();

      if (aux.length == 2) {
        // Objetivo: Guardar en localstorage los datos informativos sobre los archivos Fiel (excepto Data).

      } else if (aux.length < 2) {
        // Si solo se recuperó uno o ningún elemento, rellenar el arreglo con null.
        for (let i = 2; i >= aux.length + 1; i--) {
          aux.push(null);
        }
      }
    } catch (error) {
      console.error('async obtenerArchivosFiel(): ', error.error.stack ?? error.error);
    }

    return aux;
  }

  validateFiles(fielFiles: FielModel[]): FielData {
    const result: FielData = {
      cerFile: {
        isValid: false,
        file: undefined
      },
      keyFile: {
        isValid: false,
        file: undefined
      }
    };

    if (!fielFiles || fielFiles.length === 0) {
      console.warn('No hay archivos para validar');
      return result;
    }

    // Filtrar archivos nulos
    const validFiles = fielFiles.filter(file => file != null);

    for (const file of validFiles) {
      const fileExtension = file.FileName.toLowerCase().split('.').pop() || '';

      // Validar archivo .cer por extensión y mime type
      if (fileExtension === 'cer' && this.validCerFileTypes.includes(file.MimeType)) {
        result.cerFile.file = file;
        result.cerFile.isValid = true;
      }
      // Validar archivo .key solo por extensión
      else if (fileExtension === 'key') {
        result.keyFile.file = file;
        result.keyFile.isValid = true;
      }
    }

    console.log('Estado de archivos e.firma:', {
      cerValid: result.cerFile.isValid,
      keyValid: result.keyFile.isValid
    });

    return result;
  }

  saveProfile() {
    // Crear la estructura de la propiedad "Location" apropiada para la API.
    const newFormatLocation = {
      "type": "Point",
      "coordinates": [
        this.address.Location ? this.address.Location.lng : 0,
        this.address.Location ? this.address.Location.lat : 0
      ]
    };

    this.address.Location = newFormatLocation;

    // Formar el cuerpo con los datos de la solicitud para la API.
    const body = {
      firstName: this.profile.User.firstName,
      birthday: this.profile.User.birthday,
      lastName: this.dadName + ' ' + this.momName,
      email: this.profile.User.email,
      phoneNumber: this.profile.User.phoneNumber,
      ContactName: this.profile.User.ContactName,
      ContactPhoneNumber: this.profile.User.ContactPhoneNumber,
      Address: this.address,
      Person: this.profile.Person
    }

    // Descartar las propiedades no requeridas por la API.
    for (let p of ['id', 'Alias', 'Active', 'created_at', 'updated_at', 'PersonId', 'ReportId'])
      delete body.Address[p];

    for (let p of ['id', 'Name', 'RET', 'created_at', 'updated_at', 'user_id'])
      delete body.Person[p];

    // Subir los cambios realizados al perfil y al usuario en si.
    this.apiService.updateUser(this.id, body).subscribe((data) => {
      this.handleUserValidate();
    },
      error => {
        this.handleUserError(error.errors[0].instance.path);
      })
  }

  private handleUserValidate() {
    const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: 'Perfil actualizado exitosamente',
      showClose: true,
      timeout: 1700
    };
    this.onEdit = false;
    this.toastyService.success(toastOptions);
  }

  private handleUserError(path: string) {
    const toastOptions: ToastOptions = {
      title: 'Usuario',
      msg: path === 'TaxId' ? 'El RFC ya existe' : 'Perfil actualizado exitosamente',
      showClose: true,
      timeout: 1700
    };
    this.onEdit = false;
    this.toastyService.success(toastOptions);
  }

  uploadCerFile(file: any, toUpload: boolean = true) {
    const Files = file.target.files;

    if (Files.length > 1) {
      console.log('No se puede ajuntar más de un archivo.');
    } else if (Files.length == 1) {
      // Se recibe el certificado y se procede con los pasos posteriores
      const File = Files[0];
      // Validar que realmente el tipo de archivo sea un cer
      if (this.validCerFileTypes.includes(File.type) && File.size <= 2500) {
        // Subir el archivo mediante el uso de la API

        const formData = new FormData();
              formData.append('UploadFile', File);

        const that= this;

        const cerActualizado = function(){
          that.fechaUltimaVez.cer= null;
          that.isCerLoaded= true;
          that.buttons[0].removeAttribute('disabled');
        };


        this.buttons[0].setAttribute('disabled','');
        if(toUpload){
          this.apiService.uploadFielFile(formData).subscribe(
            (response) => {
              console.log(response);
              cerActualizado();
            },
            (error) => console.error(error.error.stack ?? error.error)
          );
        }else{
          // Actualizar el cer
          if(this.fielFiles && Array.isArray(this.fielFiles) && this.fielFiles.length > 0 && this.fielFiles[0]){
            if(this.fielFiles[0].id && this.fielFiles[0].id != null){
              this.apiService.updateFielFile(this.fielFiles[0].id,formData).subscribe(
                (response) => {
                  console.log(response);
                  cerActualizado();
                },
                (error) => console.error(error.error.stack ?? error.error)
              );
            }
          }
        }

        return;
      } else {
        console.log('El tipo de archivo que adjuntó no es un cert.');
      }
    } else {
      console.log('No adjuntó ningun archivo.');
    }

    return;
  }

  uploadKeyFile(file: any, toUpload: boolean = true) {
    const Files = file.target.files;

    if (Files.length > 1) {
      console.log('No se puede ajuntar más de un archivo.');
    } else if (Files.length == 1) {
      // Se recibe el certificado y se procede con los pasos posteriores
      const File = Files[0];
      const FileNS = File.name.split('.');
      const FileExt = FileNS[FileNS.length - 1];

      // Validar que el tipo de archivo sea un .key
      if (FileExt == 'key' && File.type != 'application/vnd.apple.keynote' && File.size <= 5000) {

        const formData = new FormData();
        formData.append('UploadFile', File);

        const that= this;

        const keyActualizado = function(){
          that.fechaUltimaVez.key = null;
          that.isKeyLoaded = true;
          that.buttons[1].removeAttribute('disabled');
        };


        this.buttons[1].setAttribute('disabled','');
        if(toUpload){
          this.apiService.uploadFielFile(formData).subscribe(
            (response) => {
              console.log(response);
              keyActualizado();
            },
            (error) => console.error(error.error.stack ?? error.error)
          );
        }else{
          // Actualizar el key (clave privada)
          if(this.fielFiles && Array.isArray(this.fielFiles) && this.fielFiles.length > 1 && this.fielFiles[this.fielFiles.length]){
            if(this.fielFiles[this.fielFiles.length].id && this.fielFiles[this.fielFiles.length].id != null){
              this.apiService.updateFielFile(this.fielFiles[this.fielFiles.length].id,formData).subscribe(
                (response) => {
                  console.log(response);
                  keyActualizado();
                },
                (error) => console.error(error.error.stack ?? error.error)
              );
            }
          }
        }

        return;
      } else {
        console.log('El tipo de archivo que adjuntó no parece ser una clave privada.');
      }
    } else {
      console.log('No adjuntó ningun archivo.');
    }

    return;
  }
}
