import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TramiteService } from '../tramite.service';
import { ToastOptions, ToastyConfig, ToastyService } from 'ng2-toasty';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { getHeaders } from '../tramite.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { UserModel } from '../../auth/auth.models';
import { environment } from '@/environments/environment';
import * as html2pdf from 'html2pdf.js'
import { ApiService } from '@/app/shared/service/api.service';

@Component({
  selector: 'app-ver-tramite',
  templateUrl: './ver-tramite.component.html',
  styleUrls: ['./ver-tramite.component.scss'],
})
export class VerTramiteComponent implements OnInit {
  customColor = '#0f4260';
  tramiteId: string;
  tramiteDetails: any;

  comments: string = '';
  isButtonsDisabled: boolean = false;
  isAcceptAndContinueDisabled: boolean = true;
  isPrevencionRespondida: boolean = false;

  private statusChangeSubscription: Subscription;

  // Información de la solicitud del trámite
  nombretramite: string;
  /* codigo: string; */
  folio: string;
  dependencia: string;
  area: string;
  fechacreacion: string;
  status: string = '';
  atendidoPor: string;
  fechaupdate: string;

  //Documentos
  documentos: any[] = []; // Array para almacenar la información de los documentos
  documentoActivo: any = null; // Documento activo que se mostrará en el visualizador
  displayedColumns: string[] = ['documento', 'aceptado', 'rechazado'];

  //Historial de la solicitud
  historial: any[]= null;

  //Datos Generales
  selectedOption: string;
  nombre: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  fecha_nacimiento: string;
  rfc: string;
  celular: string;
  telefono: string;
  calle: string;
  numExt: string;
  numInt: string;
  colonia: string;
  CodPos: string;
  municipio: string;
  estado: string;
  referencias: string;

  // CitizenSurvey
  citizenSurveys: any[] = []; // Array para almacenar los datos de CitizenSurvey
  mostrarDatosFormulario: boolean = true;
  mostrarCampoDatosIncorrectos: boolean = true;
  isEditableSurveyComment: boolean = false;

  auxSurveyComment: string = '';

  isExpanded: boolean = true;
  isCollapsed: boolean = false;

  allDataFromAPI: any[] = [];
  dataFromAPI: any[] = [];
  requirementsFiles: string[] = []; // Aquí guardaremos los archivos base64

  errorMessage: string = ''; // Declaración de la propiedad errorMessage
  public documentSelect: any;
  adminResponse: string = '';
  indexDocument: any;
  showDocumento: any;
  formSelected: any;
  surveyComment: string = '';

  commentsAdded: string = '';
  comentariosFormatoFinal: string = '';

  emptyRefusedComment: boolean = false;

  estatusNoRequiereRevision: any = [
    'Aceptado',
    'En Proceso',
    'Caducado',
    'Prevención',
    'Rechazado',
    'Finalizado',
    'Pendiente de Pago',
    'Pagado',
    'Pendiente de firma',
    'Trámite aceptado',
    'Trámite rechazado'
  ];

  isTramitePagado: boolean = false;
  isTramitePendientePago: boolean = false;

  isCursorLoading: boolean = false;
  isLoadingDocument: boolean = false;

  private modalConfig: any = {
    ariaLabelledBy: 'modal-basic',
    size: 'lg',
    centered: true
  };
  public _modalValidarPrevencion: NgbModalRef = null;
  private fielModal: NgbModalRef = null;

  public passwdFirma: string = "";
  public formsDetails: any;
  public userDetails: any;
  public signatureDetails: any = null;

  constructor(
    private route: ActivatedRoute,
    private tramiteService: TramiteService,
    private apiService: ApiService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private http: HttpClient // Agrega HttpClient para hacer la solicitud HTTP adicional
  ) {
    this.citizenSurveys = [];
    this.toastyConfig.theme = 'bootstrap';
  }

  closeModal() {
    this.modalService.dismissAll()
  }

  openFielModal(content: any){
    this.modalConfig.backdrop= 'static';
    this.modalConfig.keyboard= false;
    this.fielModal= this.modalService.open(content, this.modalConfig);
  }

  closeFielModal() {
    //this.updateStatus('Finalizado');
  }

  togglePanel(): void {
    this.isExpanded = !this.isExpanded;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.tramiteId = params.get('id');
      this.getTramiteDetails();
      // Cuando la variable tenga un valor en lugar de "undefined"..
      this.getTramiteHistory(this.tramiteId);
    });

    this.statusChangeSubscription = this.tramiteService.tramiteStatusChanged$.subscribe(
      (data: any) => {
        if (data?.tramiteId === this.tramiteId) {
          this.getTramiteDetails();

          // Cuando la variable cambie su valor..
          this.getTramiteHistory(this.tramiteId);
        }
      }
    );
  }

  ngOnDestroy(): void {
    // Desuscribirse para evitar fugas de memoria
    if (this.statusChangeSubscription) {
      this.statusChangeSubscription.unsubscribe();
    }
  }

  checkStatus(): void {
    const status = this.status || localStorage.getItem('currentStatus');

    console.log('checkStatus():', status);

    if(this.estatusNoRequiereRevision.includes(status)) {
      this.isButtonsDisabled = true;
      this.mostrarCampoDatosIncorrectos = false;
      this.isEditableSurveyComment = false;
    }else {
      this.isButtonsDisabled = false;
      this.mostrarCampoDatosIncorrectos = true;
      this.isEditableSurveyComment = true;
    }

    if (status === 'Prevención respondida') {
      this.isPrevencionRespondida = true;
    }

    console.log('this.tramiteDetails.status: ', this.tramiteDetails);
    if(status === 'Aceptado'){
      this.updateStatus('Pendiente de firma');
    }

    if(status === 'Pendiente de Pago'){
      this.isTramitePendientePago = true;
    }

    if(status === 'Pagado'){
      this.updateStatus('Pendiente de firma');
    }

    if(status === 'Pendiente de firma'){
      this.isTramitePagado = true; // Independiente si el trámite es gratuito o no
    }

    if(status === 'Trámite rechazado'){
      // ...
    }

    if(status === 'Trámite aceptado'){
      // ...
    }
  }
  
  async getTramiteDetails() {
    if (!this.tramiteId) {
      console.error('El ID del trámite no es válido:', this.tramiteId);
      this.sendNotification('Error', 'El ID del trámite no es válido', 'error');
      return;
    }

    this.tramiteService.getTramiteById(this.tramiteId)
      .subscribe(
        async (data: any) => {
          if (data && data.CitizenRequest && data.UserProfile) {
            const formsCommonCode = data.Form?.FormsCommon?.Code ?? 'Null';
            const formCommonName = data.Form?.FormsCommon?.Name ?? 'Null';

            this.nombretramite = `${formsCommonCode} - ${formCommonName}`;
            this.folio = data.CitizenRequest?.Folio ?? 'Null';
            this.area = data.Form?.Areas?.Name ?? 'Null';
            this.dependencia = data.Form?.Areas?.Departments?.Name ?? 'Null';
            this.fechacreacion = this.formatDate(data.CitizenRequest?.created_at) ?? 'Null';
            this.fechaupdate = this.formatDate(data.CitizenRequest?.updated_at) ?? 'No actualizada';

            // Verificar las fases, incluyendo inactivas.
            this.setStatus(data.Phase);

            // Obtener el forms base en relación al trámite
            this.formsDetails= await this.apiService.getTramiteFormById(data?.Form?.id).toPromise();
            console.log('formsDetails: ',this.formsDetails);

            // Establecer la variable 'Atendido Por'.
            this.setAtendidoPor();

            // Recuperar los datos del usuario
            this.selectedOption = data.UserProfile?.TaxRegime ?? 'Null';
            this.rfc = data.UserProfile?.TaxId ?? 'Null';

            // Consultar datos del perfil del usuario
            const userData = await this.authService.getUserV2Async(data.UserProfile.user_id);
            this.setUserData(data, userData);

            this.tramiteDetails = data;
            console.log(this.tramiteDetails);

            // Procesar los documentos obtenidos para su revisión.
            this.processDocuments(data.CitizenRequirements);

            // Procesar los detalles del formulario obtenido para su revisión.
            this.processSurveys(data.CitizenSurvey);

            // Obtener el primer documento a visualizar
            this.getFirstDocument();

            // Checar el estatus actual del trámite.
            this.checkStatus();
          } else {
            console.error('Los datos recibidos no contienen los detalles del trámite actual.');
          }
        },
        (error: any) => {
          console.error('Error al obtener los detalles del trámite:', error);
        }
      );
  }

  private setStatus(phases: any[]) {
    if (phases && phases.length > 0) {
      const activePhases = phases.filter((phase: any) => phase.IsActive);

      if (activePhases.length > 0) {
        this.status = activePhases[activePhases.length - 1].Status;
      } else {
        // Si no hay fases activas, buscar la última fase no activa con un estado significativo
        const lastPhase = phases[phases.length - 1];
        this.status = lastPhase ? lastPhase.Status : 'No disponible';
      }
    } else {
      this.status = 'No disponible';
    }
  }
  
  private setAtendidoPor() {
    const user = JSON.parse(localStorage.getItem('currentUser'));

    if (user && user.displayName) {
      const storedAtendidoPor = localStorage.getItem(`atendidoPor_${this.tramiteId}`);
      if (!storedAtendidoPor) {
        localStorage.setItem(`atendidoPor_${this.tramiteId}`, user.displayName);
        this.atendidoPor = user.displayName;
      } else {
        this.atendidoPor = storedAtendidoPor;
      }
    } else {
      this.atendidoPor = 'Usuario desconocido';
    }
  }
  
  private setUserData(data: any, userData: any) {
    console.log('userData:');
    console.log(userData);
    
    // Recuperar los detalles del usuario
    this.userDetails = userData ?? { Address: null, Person: null, User: null };
    console.log('userDetails: ', this.userDetails);
    
    const fullName = userData?.User?.firstName ?? 'Null';
    const lastNames = userData?.User?.lastName ?? 'Null';

    const names = fullName !== 'Null' ? fullName.split(' ') : '-'; // Divide el nombre completo por espacios en blanco
    const apellidos = lastNames.split(' ');

    this.nombre = names[0]; // El primer elemento es el nombre

    if (Array.isArray(apellidos) && apellidos.length == 2) {
      // Si hay al menos tres partes en el nombre, asumimos que el segundo y tercer elemento son los dos apellidos
      this.apellidoPaterno = apellidos[1]; // El segundo elemento es el primer apellido
      this.apellidoMaterno = apellidos[0]; // El primer elemento es el segundo apellido
    } else {
      // Si hay menos de dos partes en el nombre, algo está mal, puedes manejarlo según sea necesario
      this.apellidoPaterno = '-';
      this.apellidoMaterno = '-';
    }

    this.fecha_nacimiento = userData?.User?.birthday ?? 'Null';
    this.celular = data?.UserProfile?.MobilePhone ?? 'Null';
    this.telefono = data?.UserProfile?.OfficePhone ?? 'Null';
    this.calle = userData?.Address?.Street ?? 'Null';
    this.numExt = userData?.Address?.ExtNumber ?? 'Null';
    this.numInt = userData?.Address?.IntNumber ?? 'Null';
    this.colonia = userData?.Address?.Neighborhood ?? 'Null';
    this.CodPos = userData?.Address?.ZipCode ?? 'Null';
    this.municipio = userData?.Address?.City ?? 'Null';
    this.estado = userData?.Address?.State ?? 'Null';
    this.referencias = userData?.Address?.Notes ?? 'Null';
  }
  
  private processDocuments(requirements: any[]) {
    if (requirements && requirements.length > 0) {
      this.documentos = requirements.map((documento: any, index: number) => {
        return {
          id: documento.id,
          index: index + 1,
          CitizenRequestId: documento.CitizenRequestId,
          RequirementId: documento.Requirements?.id ?? 'Null',
          FilePath: documento.FilePath,
          status: documento.IsValid ?? null,
          documentUpload: documento.FilePath ? true : false,
          MimeType: documento.MimeType, // Agrega el campo MimeType
          Name: documento.Requirements.Name ?? 'Null', // Usamos el nombre del documento en lugar de FilePath
          Comments: documento.Comments ?? 'Null',
          IsValid: documento.IsValid ?? false
        };
      })

      if (this.documentos && this.documentos.length > 0) {
        this.documentoActivo = this.documentos[0];
      }

      console.log(this.documentos)
    }
  }
  
  private processSurveys(surveys: any[]) {
    if (surveys && surveys.length > 0) {
      this.citizenSurveys = surveys.map((surveySection: any) => {
        this.surveyComment = (surveySection.Survey) ? surveySection.Survey[0].Comments : '';

        return {
          id: surveySection.id,
          Section: surveySection.Section,
          Survey: surveySection.Survey.map((surveyItem: any) => {
            if( surveyItem.IsValid === false && this.surveyComment == '' ){
              this.surveyComment = surveyItem.Comments;
            }

            return {
              Question: surveyItem.SurveyForm.Question ?? 'Null',
              Answer: surveyItem.Answer ?? 'Null',
              Comments: surveyItem.Comments ?? 'Null'
            };
          })
        };
      });
    }
  }

  getTramiteHistory(tramiteId: string | number): void {
    // Obtener el historial del trámite
    this.tramiteService.getTramiteHistory( tramiteId ).subscribe(
      (historial) => this.historial = this.formatTramiteHistory(historial),
      (error) => console.error(error)
    );
  }

  formatTramiteHistory(h: any) {
    const optionsDate: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
    const optionsTime: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };

    return h.map((hs) => {
      const date = new Date(hs.created_at);
      return {
        ...hs,
        fechaFormateada: date.toLocaleDateString('es-MX', optionsDate),
        horaFormateada: date.toLocaleTimeString('es-MX', optionsTime)
      };
    });
  }

  modalValidarPrevencion(content: any): void {
    this._modalValidarPrevencion= this.modalService.open(content, this.modalConfig);
    return;
  }

  public async verifyUserByPasswd(passwd: string): Promise<boolean> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const userModel: UserModel = {
      email: currentUser.email,
      password: passwd
    };

    return new Promise((resolve, reject) => {
      this.http.post(`${environment.API_URL}/auth/signin`, userModel).subscribe(
        (data) => { console.log(data);  resolve(true); },
        (error) => { console.error(error); resolve(false); }
      );
    });
  }

  async validarPrevencionContra(passwd: HTMLInputElement) {
    const result = await this.verifyUserByPasswd(passwd.value);

    console.log( result );

    if(result){
      // Enviar el trámite a estatus de "Prevención".
      this.updateStatus('Prevención');
      return;
    }

    this.sendNotification('Contraseña Incorrecta','La contraseña que ha proporcionado no corresponde con la del usuario actual.','error',4000);
    return;
  }

  async getFirstDocument() {
    const documents = this.documentos.filter((doc: any) => doc.documentUpload);
    const headers = getHeaders();

    if (!documents.length) {
      console.error('No hay documentos por procesar.');
      return;
    }

    if (!headers) {
      console.error('Los headers no están configurados correctamente.');
      return;
    }

    try {
      // Seleccionar el primer documento para mostrar su contenido al cargar la página
      const documento = documents[0];
      const response: any = await this.http.get(`https://veraxapi.stackcode.io/api/miveracruz/get_requirements_files/${documento.id}`, { headers }).toPromise();
  
      if (!response) {
        console.warn('Respuesta nula para algún documento, continuando con el siguiente.');
        return;
      }
  
      const documentBase64 = response.Data.toString();
  
      // Actualizar el índice y mostrar la selección del documento
      this.indexDocument = documento.index + 1;
      this.showDocumento = documento;
  
      // Validar la existencia del contenido del documento y su tipo MIME
      if (documentBase64.length > 0 && response.MimeType) {
        this.showDocument(documentBase64, response.MimeType);
  
        // Almacenar los datos del documento para futuros usos
        documento.base64 = response.Data;
        documento.MimeType = response.MimeType;
      } else {
        this.sendNotification('Error en el documento', 'No existe el documento cargado por el ciudadano.', 'error', 6000);
      }
    } catch (error) {
      console.error('Error al cargar el documento:', error);
    }
  }

  previousDocument() {
    if (this.showDocumento.index > 1) {
      this.showDocumento = this.documentos[this.showDocumento.index - 2];
    } else {
      this.showDocumento = this.documentos[this.documentos.length - 1];
    }
    this.seleccionarDocumento(this.showDocumento);
  }

  nextDocument() {
    if (this.showDocumento.index < this.documentos.length) {
      this.showDocumento = this.documentos[this.showDocumento.index];
    } else {
      this.showDocumento = this.documentos[0];
    }

    this.seleccionarDocumento(this.showDocumento);
  }

  openNewDocument() {
    const blob = this.base64ToBlob(this.showDocumento.base64, this.showDocumento.MimeType);
    window.open(URL.createObjectURL(blob), '_blank')
  }

  public base64ToBlob(base64Data: string, contentType = '', sliceSize = 512): Blob {
    // Remove any whitespace characters (for IE compatibility)
    base64Data = base64Data.replace(/\s/g, '');

    // Decode the base64 string
    const byteCharacters = atob(base64Data);

    // Create an array of byte arrays (sliced for performance)
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // Create the Blob
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async seleccionarDocumento(documento: any) {
    
    if (documento && documento.base64 && documento.MimeType) {
      this.showDocumento = documento;

      const base64Data = documento.base64;
      const mimeType = documento.MimeType;

      // Llamar a la función showDocument() con base64Data y mimeType, y mostrar el documento.
      this.showDocument(base64Data, mimeType);
    } else {
      const headers = getHeaders();
      
      if( this.isLoadingDocument == false ){ // Si "isLoadingDocument" es verdadero, significa que ya se está intentando cargar un documento.
        try{
          this.isLoadingDocument = true;
          this.isCursorLoading = true;

          const response = await this.http.get<any>(`https://veraxapi.stackcode.io/api/miveracruz/get_requirements_files/${documento.id}`, { headers }).toPromise();

          if (!response) { // Se informa en la consola de javascript y se interrumpe el resto del proceso ya que no se recibió ningun documento del servidor.

            console.error('No se pudo obtener el documento solicitdado.');
            this.isLoadingDocument = false; // Marcar que un documento no está siendo cargado.
            this.isCursorLoading = false; // Desactivar el cursor en modo cargando.

            return;
          }

          const documentBase64 = response.Data.toString();

          // Mostrar la selección del documento.
          this.indexDocument = documento.index + 1;
          this.showDocumento = documento;

          // Validar que se tenga el documento y mostrarlo, caso contrario notificar un error.
          if( documentBase64.length > 0 && response.MimeType ){
            this.showDocument(documentBase64, response.MimeType);

            documento.base64 = response.Data;
            documento.MimeType = response.MimeType;
          }else{
            this.sendNotification('Error en el documento', 'No existe el documento cargado por el ciudadano.', 'error');
          }

          this.isLoadingDocument = false; // Marcar que un documento no está siendo cargado.
          this.isCursorLoading = false; // Desactivar el cursor en modo cargando.

        }catch(error){
          console.error('seleccionarDocumento():', error);
        }
      }
    }
  }

  // Método para mostrar el documento en el visor
  showDocument(base64Data: string, mimeType: string): void {
    if (base64Data && mimeType) {
      // Crear un elemento para mostrar el documento
      let element: HTMLElement;

      if (mimeType === 'application/pdf') {
        // Si es un PDF, crear un elemento embed para mostrarlo
        element = document.createElement('embed');
        element.setAttribute('src', 'data:application/pdf;base64,' + base64Data);
        element.setAttribute('type', 'application/pdf');
        element.setAttribute('width', '100%');
        element.setAttribute('height', '600px');
      } else if (mimeType.startsWith('image/')) {
        // Si es una imagen, crear un elemento img para mostrarla
        element = document.createElement('img');
        element.setAttribute('src', 'data:' + mimeType + ';base64,' + base64Data);
        element.style.maxWidth = '100%';
      } else {
        // Tipo de archivo no compatible
        console.error('Tipo de archivo no compatible:', mimeType);
        return;
      }

      // Verificar si el elemento viewerContainer está definido
      const viewerContainer = document.getElementById('documentViewer');
      if (viewerContainer) {
        // Limpiar el contenedor del visualizador
        viewerContainer.innerHTML = '';

        // Agregar el elemento al contenedor del visualizador
        viewerContainer.appendChild(element);
      } else {
        console.error('No se encontró el elemento con id "documentViewer" en el HTML');
      }
    } else {

      const viewerContainer = document.getElementById('documentViewer');
      if (viewerContainer) {
        viewerContainer.innerHTML = '';
      } else {
        console.error('No se encontró el elemento con id "documentViewer" en el HTML');
      }
      
      this.errorMessage = 'El contenido del documento, es decir, el base64Data o el mimeType son indefinidos o nulos';
      console.error( this.errorMessage );
    }
  }

  detectFileType(base64Data: string): string {
    if (!base64Data) {
      console.error('El base64Data es indefinido o nulo');
      return ''; // O puedes manejarlo según tus necesidades
    }

    // Verificar el tipo de archivo basado en el base64Data
    if (base64Data.startsWith('data:image')) {
      return 'image';
    } else if (base64Data.startsWith('data:application/pdf')) {
      return 'pdf';
    } else {
      console.error('Tipo de archivo no compatible:', base64Data);
      return ''; // O puedes manejarlo según tus necesidades
    }
  }
  
  isDocumentsReviewed(){
    const documentsReviewed = this.documentos.filter((d) => d.status != null);
    
    if(documentsReviewed.length == this.tramiteDetails.CitizenRequirements.length){
      return true;
    }

    return false;
  }
  
  // Now you can use the 'blob' for further processing (e.g., displaying or downloading)
  approveDocument(documento: any, index: number) {

    // Independientemente del estado, si el trámite ya sufrió de un cambio ya no se puede volver a modificar mediante interacciones.
    if ( (this.tramiteDetails?.CitizenRequest?.updated_by &&
        this.tramiteDetails?.CitizenRequest?.updated_by !== this.tramiteDetails?.CitizenRequest?.created_by) && 
        this.status !== 'Prevención respondida')
    {
      this.sendNotification('Error', 'No se puede realizar está acción', 'error');
      return;
    }

    console.log('current index of document approve:');
    console.log(index);

    if(this.showDocumento.index == index){
      // "documento" se obtiene desde html (mediante el listado de documentos) al seleccionar la opción "aceptado" en la fila del documento
      // Se actualiza el estatus del documento seleccionado a aprovar.
      documento.status = true;
      documento.Comments = 'Null';
    }

    console.log('documento:');
    console.log(documento);
  }

  openCommentsDoc(content: any, documento: any, pos: number) {
    // content: corresponde al id del modal en html
    if(documento.Comments != '' && documento.Comments != 'Null'){
      
      this.documentSelect = documento;

      this.commentsAdded = this.documentSelect.Comments;

      this.modalService.open(content, this.modalConfig);
    }
  }

  rejectForm(content) {
    if( this.isDocumentsReviewed() ){
      // Se reinicia el contenido del comentario de "¿Los datos son correctos?".
      this.surveyComment = '';
      // Se abre el modal para enviar un comentario y negar el status del trámite por Datos del formulario incorrectos.
      this.modalService.open(content, this.modalConfig);
    }else{
      this.sendNotification(null, 'Los Documentos no han sido revisados. Favor de revisar antes de continuar.', 'warning');
      return;
    }
  }

  confirmCorrectData(event: Event, content: any){

    if( this.isDocumentsReviewed() ){
      // mostrar modal para confirmar cambios.
      this.modalConfig.size = 'md';
      this.modalService.open(content, this.modalConfig);

      // Regresar al modal su tamaño original.
      this.modalConfig.size = 'lg';
    }else{
      this.sendNotification(null, 'Los Documentos no han sido revisados. Favor de revisar antes de continuar.', 'warning');
      return;
    }
  }

  confirmFormData(){
    // Si desde el modal de "Confirmar Cambios" (de Datos del formulario) se da en aceptar...
    
    // Ocultar campo ¿Los datos son correctos? de Datos del formulario
    this.mostrarCampoDatosIncorrectos= false;

    this.closeModal();

    // Validar si no existe algun Documento rechazado
    const aDocumentInvalid = this.documentos.filter((d) => d.status == false );
    if(aDocumentInvalid.length > 0){
      // Deshabilitar el botón "Aceptar y Continuar"
      this.isAcceptAndContinueDisabled= true;
    }else{
      this.isAcceptAndContinueDisabled= false;
    }

    // Se establece IsValid como verdadero y comentarios en Null
    for(let ctsurvey of this.tramiteDetails.CitizenSurvey)
      for(let cmmsurvey of ctsurvey.Survey){
        cmmsurvey.IsValid = true;
        cmmsurvey.Comments = '';
      }
    
  }

  saveIncorrectDataComments(event: Event){
    // Guardar los comentarios en las preguntas de los Datos del formulario
    if(this.surveyComment.length > 0){
      // Asignar comentario realizado por el admin al formulario del ciudadano
      for(let ctsurvey of this.tramiteDetails.CitizenSurvey){

        /** Se asigna el comentario "surveyComment" al campo "Comments" de las preguntas de Datos del formulario **/
        for(let cmmsurvey of ctsurvey.Survey){
          cmmsurvey.IsValid = false;
          cmmsurvey.Comments = this.surveyComment;
        }
      }

      // Ocultar campo ¿Los datos son correctos? de Datos del formulario
      this.mostrarCampoDatosIncorrectos= false;

      // Deshabilitar el botón "Aceptar y Continuar", ya que se han invalidado los Datos del formulario
      this.isAcceptAndContinueDisabled= true;

      this.closeModal();
    }else{
      this.sendNotification('Error', "No se puede continuar si no ha ingresado un motivo al ciudadano.", 'error');
    }
    
    this.auxSurveyComment = '';
  }

  editCommentSurvey(content: any){
    // Guardar el texto original (observaciones del Dato de formulario)
    this.auxSurveyComment = this.surveyComment;

    this.modalService.open(content, this.modalConfig); 
  }

  undoSurveyComment(){
    this.surveyComment = '';

    this.isAcceptAndContinueDisabled= true;

    this.mostrarCampoDatosIncorrectos = true;
  }

  openRejectRequest(content: any) {
    this.comments= '';
    this.modalService.open(content, this.modalConfig);
  }

  openPrevencionRequest(content: any) {
    this.comments = '';
    this.modalService.open(content, this.modalConfig);
  }

  openReject(content: any, documento: any, index: number) {
    // Independientemente del estado, si el trámite ya sufrió de un cambio ya no se puede volver a modificar mediante interacciones.
    if (this.tramiteDetails?.CitizenRequest?.updated_by &&
      this.tramiteDetails?.CitizenRequest?.updated_by !== this.tramiteDetails?.CitizenRequest?.created_by &&
      this.status !== 'Prevención respondida')
    {
      this.sendNotification('Error', 'No se puede realizar está acción', 'error');
      return;
    }
    
    if(this.showDocumento.index == index){
      documento.index = index;
      // Se actualiza el estatus del documento seleccionado a no aprovado.
      documento.status = false;

      this.documentSelect = documento;

      // Se valida que al abrir el modal no se elmine el comentario agregado (si existe).
      if(this.documentSelect.Comments && (this.documentSelect.Comments == 'Null' || this.documentSelect.Comments == null)){
        this.documentSelect.Comments = '';
      }
      
      this.modalService.open(content, {...this.modalConfig, backdrop: 'static'});      
    }
  }

  cancelReject(){
    // Se valida que al cancelar la operación "rechazar documento" no se elimine los cambios si hay un comentario agregado.
    if(this.documentSelect.Comments && (this.documentSelect.Comments == 'Null' || this.documentSelect.Comments == null)){
      this.documentSelect.Comments= '';
    }
    // Se valida que si no hay comentarios agregados, entonces que se descarte la operación "rechazar documento"
    if(this.documentSelect.Comments == '' || this.documentSelect == null){
      this.documentSelect.status= null;
    }

    this.closeModal();
  }

  openModal(content: any){
    this.modalService.open(content, this.modalConfig);
    return;
  }

  getIndispensableSurveyData(){
    const surveysObject = [];

    // Recorrer cada sección de Survey
    for(let survey of this.tramiteDetails?.CitizenSurvey){
      Object.keys(survey.Survey).forEach((e, i) => {
        // acceder a cada pregunta de la seccion
        surveysObject.push({
          id: survey.Survey[i].id,
          IsValid: survey.Survey[i].IsValid
        });
      });
    }

    // Se regresa el objeto de las preguntas con los datos requeridos (id e IsValid)
    return surveysObject;
  }

  updateStatus(status: string) {
    const user = JSON.parse(localStorage['currentUser']);
    const auxComments = this.comments;

    const body: any = {
      Status: status,
      RejectReason: ['Prevención','Rechazado','Caducado'].includes(status) ? auxComments : '',
      CitizenRequest: { id: parseInt(this.tramiteId), updated_by: user.id },
      CitizenRequirements: null,
      CitizenSurvey: null
    };

    // En caso que el trámite se encuentre en Pendiente Pago, Pagado, Finalizado o Aceptado.
    if(['Pendiente de firma','Pendiente de Pago', 'Pagado', 'Finalizado', 'Aceptado', 'En Revisión', 'En Proceso'].includes(status)){
      delete body.CitizenRequirements;
      delete body.CitizenSurvey;
    }else{
      // Organizar información esencial de los documentos del trámite.
      body.CitizenRequirements = this.documentos.map((doc: any) => {
        return {
          id: doc.id,
          IsValid: doc.status ? true : false,
          Comments: doc.Comments ?? ''
        };
      });

      // Organizar información esencial del formulario del trámite.
      body.CitizenSurvey= {
        Comments: this.surveyComment,
        Survey: this.getIndispensableSurveyData()
      };
    }
    
    this.tramiteService.updateTramite( JSON.stringify(body) ).subscribe(
      (data: any) => this.updateStatusSuccess(data, body.Status),
      (error: any) => this.updateStatusError(error)
    );
  }

  updateStatusSuccess(dataResponse: any, status: any) {
    this.sendNotification('Solicitud actualizada', 'Le notificaremos al ciudadano', 'success');
    this.comments = '';
    this.modalService.dismissAll();

    // En tiempo de interacción los botones se desactivan, al cargar/refrescar la página se realiza un chequeo y se vuelven a deshabilitar.
    if (['Aceptado','En Proceso','Caducado','Prevención','Rechazado','Finalizado','Pendiente de Pago','Pagado','Pendiente de firma'].includes(status)) {
      this.isButtonsDisabled = true;
      this.mostrarCampoDatosIncorrectos = false;
    } else {
      this.isButtonsDisabled = false;
      this.mostrarCampoDatosIncorrectos = true;
    }
    // Almacenar el estado actualizado en localStorage
    localStorage.setItem('currentStatus', status);

    // Notificar el cambio de estado (si es necesario)
    this.tramiteService.notifyTramiteStatusChange(this.tramiteId, status);
    this.getTramiteDetails(); // Agrega esta línea

    console.log(dataResponse);
  }

  updateStatusError(error: any) {
    console.error('Error al actualizar el estatus:', error);
    this.sendNotification('Error', 'Hubo un problema al actualizar el estatus', 'error');
  }

  async finalizarFirmado(){
    // Si ya se realizó el firmado..
    if(this.signatureDetails && this.signatureDetails != null){
      // Almacenar el firmado
      const data = {
        CitizenRequestId: this.tramiteDetails?.CitizenRequest?.id ?? null,
        Observations: this.comentariosFormatoFinal ?? '',
        Signature: this.signatureDetails?.Signature ?? null
      };

      // Si se tiene el id del trámite y se cuenta con la firma..
      if(data.CitizenRequestId != null && data.Signature != null){
        const rawData = JSON.stringify( data ); // Convertir objeto en raw Json

        try{
          const response= await this.tramiteService.createDocumentSigned(rawData).toPromise();
          console.log('response: ',response);

          if(response){
            // Actualizar el estatus del trámite a 'Prevención'
            if(this.status && this.status == 'Pendiente de firma'){
              this.updateStatus('Finalizado');
            }
          }
        }catch(exception){
          console.error('Exception: ',exception);
        }
      }
    }
  }

  limit150(event: Event) {
    var inputValue = event.target as HTMLTextAreaElement | HTMLInputElement;

    let value: string = this.getAttrTextByNameRef(inputValue.name);

    if(value.length > 150){
      const valorTruncado = value.slice(0,150);

      switch(inputValue.name){
        case "c":
        case "c2":
          this.comments = valorTruncado;
        break;
        case "dSC":
          this.documentSelect.Comments = valorTruncado;
        break;
      }

      if(inputValue){
        inputValue.value = inputValue.value.slice(0,150);
      }
    }
  }

  limit300(event: Event) {
    var inputValue = event.target as HTMLTextAreaElement | HTMLInputElement;

    let value: string = this.getAttrTextByNameRef(inputValue.name);

    if(value.length > 300){
      const valorTruncado = value.slice(0,300);

      switch(inputValue.name){
        case "c":
          this.comments = valorTruncado;
        break;
        case "sC":
          this.surveyComment = valorTruncado;
        break;
        case "cFF":
          this.comentariosFormatoFinal = valorTruncado;
        break;
      }

      if(inputValue){
        inputValue.value = inputValue.value.slice(0,300);
      }
    }
  }

  private getAttrTextByNameRef(attributeNameValue: string): string {

    if(attributeNameValue === "c" || attributeNameValue === "c2") {
      return this.comments;
    }
    else if(attributeNameValue === "sC") {
      return this.surveyComment;
    }
    else if(attributeNameValue === "cFF") {
      return this.comentariosFormatoFinal;
    }
    else if(attributeNameValue === "dSC") {
      return this.documentSelect.Comments;
    }
    
    return '';
  }

  handleError(event: Event) {
    console.error('Error al cargar el archivo:', event);
    // Realiza acciones adicionales según sea necesario, como mostrar un mensaje de error.
  }

  onSubmit(): void {}

  get hasNoDocuments(): boolean {
    return !this.requirementsFiles || this.requirementsFiles.length === 0;
  }

  // Función para formatear la fecha
  formatDate(dateString: string | undefined): string {
    if (!dateString) {
      // Manejar el caso cuando la cadena es undefined
      return 'Fecha no disponible';
    }
    // Ejemplo de formato de fecha: "2024-04-12T00:00:00.000Z"
    const dateParts = dateString.split('T');
    if (dateParts.length < 2) {
      // Si la cadena no tiene el formato esperado, retornar un mensaje de error
      return 'Formato de fecha inválido';
    }
    const date = dateParts[0];
    const time = dateParts[1].split('.')[0];
    const [year, month, day] = date.split('-');
    const [hour, minute, seg] = time.split(':');
    // Formatear la fecha como deseado
    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${seg}`;
    return formattedDate;
  }

  // Declaración de la propiedad fileLoadError
  fileLoadError: boolean = false;

  private sendNotification(title: string = 'Morelos', message: string, type: string, timeo: number= 3500): void {
    const toastOptions: ToastOptions = {
      title,
      msg: message,
      showClose: true,
      timeout: timeo,
      theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions);
  }

  // Funciones para la emisión de la ficha del trámite
  private formatoComprobante(page: number): string {
    let content: string;
    const props = {
      textSizeDefault: '100%',
      textColor: '#033752ff',
      fontWeight: '600',
      extraFontWeight: 'bold',
      relevantTextColor: '#48bd98ff',
      container: {
        pageContainer: 'position:relative;height:10.2in;width:100%;',
        ontop: 'position:absolute;left:0;top:0;',
        onbottom: 'position:absolute;left:0;bottom:0;'
      },
      table: {
        default: 'width:100%;',
        tdata: 'padding-top:5px;padding-bottom:6px;text-align:left;vertical-align:top;',
        tdataWp: 'padding-bottom:6px;text-align:left;vertical-align:top;',
        tdataAc: 'padding-top:5px;padding-bottom:6px;text-align:center;vertical-align:text-bottom;',
        tdataInitial: 'padding-top:5px;padding-bottom:6px;text-align:center;',
        flexStart: 'display:flex;flex-direction:column;justify-content:flex-start;',
        trow(borderBottom: boolean = true){
          if(borderBottom)
            return 'border-bottom: 2px solid #a9d9c6ff;';
          else
            return '';
        },
      },
      getLabel(fontSize: string = this.textSizeDefault) {
        return `font-size:${fontSize};
                font-weight:${this.fontWeight};
                color:${this.textColor};
                margin-bottom:0;
                padding-bottom:0;
                line-height:1.15;
        `;
      },
      getLabelBody(paddingLeft: string = '13px', borderLeft: string= '2px solid #48bd98ff') {
        return `padding-left: ${paddingLeft};
                border-left: ${borderLeft};
        `;
      },
      getText(displayType: string= 'block', fontSize: string = '84%', textAlign: string = 'justify') {
        return `color:${this.textColor};
                display:${displayType};
                text-align:${textAlign};
                font-size:${fontSize};
                line-height:1.15;
                padding-right:8px;
                margin-bottom: 4px;
        `;
      },
      getTitle() {
        return `margin-bottom:2px;
                text-transform:uppercase;
                color:${this.textColor};
                text-align:center;
                font-weight:${this.fontWeight};
                font-size:150%;`;
      },
      getSubtitle() {
        return `margin-bottom:0;
                padding-bottom:0;
                color:${this.textColor};
                text-align:center;
                font-weight:${this.fontWeight};
                font-size:94%;`;
      },
      getTitleSection() {
        return `font-size:88%;
                color:${this.relevantTextColor};
                font-weight:${this.extraFontWeight};
                padding-bottom:5px;
                letter-spacing:0;
                text-align:center;
        `;
      },
      horizontalRule(position: string = 'relative', width: string = '100%') {
        return `position:${position};
                        border: 1px solid #afdeccff;
                        margin-top:3px;
                        padding-top:0;
                        margin-bottom:6px;
                        padding-bottom:0;
                        width:${width};`;
      }
    };

    const header = () => (`
      <table style='${props.table.default} ${props.container.ontop}'>
        <tr>
          <td style='text-align:left'>
            <img src='assets/images/miveracruz/documento/d-veracruz.png' width='116'>
          </td>
          <td style='text-align:right'>
            <img src='assets/images/miveracruz/documento/d-veracruz-2.png' width='144'>
          </td>
        </tr>
      </table>
      <br><br><br><br>

      <h3 style='${props.getSubtitle()}'>DIRECCIÓN DE INGRESOS</h3>
      <h1 style='${props.getTitle()}'>
        ${this.formsDetails?.Details?.FormsCommon?.Name ?? this.tramiteDetails?.CitizenRequest?.Folio ?? 'Ficha del Trámite'}
      </h1>
      <h4 style='${props.getSubtitle()}'>Folio de trámite: ${this.tramiteDetails?.CitizenRequest?.Folio ?? '-'}</h4>

      <hr style='${props.horizontalRule()}'/>
    `);
    
    const footer = (`
      <div style='${props.container.onbottom}width:100%;text-align:center;'>
        <p style='${props.getText('block','92%','center')}font-weight:${props.fontWeight};width:100%;'>
          Av. Ignacio Zaragosa s/n Col. Centro Tel. 229 200 2000
        </p>
      </div>
    `);

    const sectionTitle = (text: string, widthHr1: string = '256px', widthHr2: string = '256px') => (`
      <table style='${props.table.default}'>
          <tr>
            <th>
              <hr style='${props.horizontalRule('relative', widthHr1)}'/>
            </th>
            <th>
              <p style='${props.getTitleSection()}position:relative;top:7px;'>
                ${text}
              </p>
            </th>
            <th>
              <hr style='${props.horizontalRule('relative', widthHr2)}'/>
            </th>
          </tr>
        </table>
    `);

    if(page==0){
      content= `<div style='${props.container.pageContainer}'> ${header()}`;

      const fechaActual: Date = new Date();
      // Ajusta la diferencia horaria local
      const offsetMs: number = fechaActual.getTimezoneOffset() * 60000;  // Offset en milisegundos
      const fechaLocalISO: string = new Date(fechaActual.getTime() - offsetMs).toISOString().slice(0, -1);  // Elimina la 'Z' del final, ya que ahora es hora local
      
      const fechaExpedicion: string = this.formatDate(fechaLocalISO);
    
      // Página número uno
      content += `
        <p style='${props.getTitleSection()}'>
          IDENTIFICACIÓN DEL BENEFICIARIO / TRAMITADOR
        </p>

        <table style='${props.table.default}'>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>NOMBRE O RAZÓN SOCIAL</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.User?.firstName ?? '-'} ${this.userDetails?.User?.lastName ?? ''}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>CURP</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.Person?.CURP ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>RFC</label> <br>
              <span style='${props.getText()}'>
              ${this.userDetails?.Person?.TaxId ?? '-'}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>TELÉFONO</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.User?.phoneNumber ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>CELULAR</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.Person?.MobilePhone ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>CORREO ELECTRÓNICO</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.Person?.ContactEmail ?? this.userDetails?.User?.email ?? '-'}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>CALLE</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.Address?.Street ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>NÚMERO EXTERIOR</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.Address?.ExtNumber ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>NÚMERO INTERIOR</label> <br>
              <span style='${props.getText()}'>
              ${(this.userDetails?.Address?.IntNumber && this.userDetails?.Address?.IntNumber.toLowerCase() == 'na' ? '-' : this.userDetails?.Address?.IntNumber) ?? '-'}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>COLONIA</label> <br>
              <span style='${props.getText()}'>
              ${this.userDetails?.Address?.Neighborhood ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>C.P.</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.Address?.ZipCode ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>MUNICIPIO</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.Address?.City ?? '-'}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>ESTADO</label> <br>
              <span style='${props.getText()}'>
                ${this.userDetails?.Address?.State ?? '-'}
              </span>
            </td>
            <td></td>
            <td></td>
          </tr>
        </table>

        ${sectionTitle('IDENTIFICACIÓN DEL TRÁMITE')}

        <table style='${props.table.default}'>
          <tr>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>TIPO DE DOCUMENTO</label> <br>
              <span style='${props.getText()}'>
                ${this.tramiteDetails?.Form?.FormsCommon?.Type ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>NOMBRE DEL TRÁMITE</label> <br>
              <span style='${props.getText()}'>
                ${this.tramiteDetails?.Form?.FormsCommon?.Name ?? '-'}
              </span>
            </td>
            <td>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>FECHA DE EXPEDICIÓN</label> <br>
              <span style='${props.getText()}'>
                ${fechaExpedicion}
              </span>
            </td>
          </tr>
          <tr>
            <td colspan='1'>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>VIGENCIA</label> <br>
              <span style='${props.getText()}'>
                ${this.tramiteDetails?.Form?.FormsCommon?.Validity ?? '-'} ${this.tramiteDetails?.Form?.FormsCommon?.ValidityType ?? ''}
              </span>
            </td>
            <td colspan='2'>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>DESCRIPCIÓN DEL TRÁMITE</label> <br>
              <span style='${props.getText()}'>
                ${this.formsDetails?.Details?.Description ?? '-'}
              </span>
            </td>
          </tr>
          <tr>
            <td colspan='3'>
              <label style='${props.getLabel()} ${props.getLabelBody()}'>FUNDAMENTO JURÍDICO</label> <br>
              <span style='${props.getText()}'>
                ${this.formsDetails?.Details?.LegalBase ?? '-'}
              </span>
            </td>
          </tr>
        </table>

        ${sectionTitle('OBSERVACIONES', '300px','300px')}
        <table style='${props.table.default}'>
          <tr>
            <td>
              <span style='${props.getText('block','84%','center')}'>
                ${this.comentariosFormatoFinal}
              </span>
            </td>
          </tr>
        </table> <br>

        ${sectionTitle('FIRMAS', '332px','332px')}
        <table style='${props.table.default}'>
          <tr>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td style='text-align:left;padding-left:10px;width:70%;word-wrap:break-word;word-break:break-all;'>
              <span style='${props.getText('inline')}font-weight:600;'>FIRMADO POR:</span>
              <span style='${props.getText('inline')}'>
                ${this.signatureDetails?.Ownwer ?? '-'}
              </span> <br>

              <span style='${props.getText('inline')}font-weight:600;'>FECHA:</span>
              <span style='${props.getText('inline')}'>
                ${fechaExpedicion}
              </span> <br>

              <span style='${props.getText('inline')}font-weight:600;'>RFC:</span>
              <span style='${props.getText('inline')}'>
                ${this.signatureDetails?.RFC ?? '-'}
              </span> <br>

              <span style='${props.getText('inline')}font-weight:600;'>CERTIFICADO:</span>
              <span style='${props.getText('inline')}'>
                ${this.signatureDetails?.Serial ?? '-'}
              </span> <br>

              <span style='${props.getText('inline')}font-weight:600;'>FIRMA:</span> <br>
              <span style='${props.getText('inline-block')}'>
                ${this.signatureDetails?.Signature ?? '-'}
              </span>
            </td>
            <td style='text-align:right;padding-right:25px;width:30%;'>
              <img src='assets/images/fake-qr.png' width='104' alt='QR CODE'>
            </td>
          </tr>
        </table>
      `;

      content += `${footer} </div>`;
    }

    return content;
  }
  
  emitirComprobanteTramite(): void {
    const paginas: any[]= [];
    let indicePagina: number= 0;

    // Crear un contenedor temporal
    paginas.push( document.createElement('div') ); // Guardar un nuevo elemento div en 'paginas'
    paginas[indicePagina].innerHTML = this.formatoComprobante(indicePagina);

    // Configuración para html2pdf
    const options = {
      margin:   0.4,
      filename: `${(this.tramiteDetails?.Form?.FormsCommon?.Name).toLowerCase()}.pdf`,
      html2canvas: { scale: 2 },
      image: { type: 'jpeg', quality: 1 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pageBreak:  { mode: ['avoid-all'] } // Control de saltos en linea
    };

    // Generar el PDF
    let doc = html2pdf().set(options).from(paginas[0]).toPdf().get('pdf');

    doc.save();
  }

  async verificarFirmaElectronica() {
    const data = JSON.stringify(
      {
        FielPassword: this.passwdFirma
      }
    );

    try {
      const response = await this.apiService.getSignatureByPasswd(data).toPromise();
      if(response){
        if(response !== null && typeof response == 'object' && !Array.isArray(response)){ // Si devolvió un objeto esperado..
          this.signatureDetails = response;
          console.log('signatureDetails: ', this.signatureDetails);

          // Autodescargar el comprobante del trámite firmado
          const link: HTMLLinkElement = document.getElementById('link-comprobante-tramite') as HTMLLinkElement;
          if(link)
            link.click();

          // Vaciar la contraseña FIEL del Frontend
          const input: HTMLInputElement = document.getElementById('passwdfiel') as HTMLInputElement;
          input.value = '*********';
          input.setAttribute('readonly','');

          this.sendNotification('Ficha del Trámite','El trámite ha sido firmado con éxito.','success');
        }else{
          console.warn('response: ', response);
        }
      }
    }catch(exception){
      console.warn(exception.error.stack ?? exception.error);
      const message = (exception.error.stack.split('\n')[0])
                          .split(':')[2]
                          .trim();

      this.sendNotification('Error',message,'error');
    }
  }
}