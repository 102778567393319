import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TramiteService {
  private tramiteStatusChangedSource = new BehaviorSubject<any>(null);
  tramiteStatusChanged$ = this.tramiteStatusChangedSource.asObservable();

  private baseUrl = environment.API_URL;
  //private apiUrl = 'http://45.85.146.201:3000/api/miveracruz/citizen_form_request';
  private apiUrl = 'https://veraxapi.stackcode.io/api/miveracruz/citizen_form_request';

  constructor(private http: HttpClient) { }

  updateTramite(body: any): Observable<any> {
    const headers = getHeaders();
    return this.http.put<any>(`${this.baseUrl}/miveracruz/citizen_form_request_status`, body, { headers });
  }

  getTramiteById(tramiteId: string | number): Observable<any> {
    const url = `${this.apiUrl}/${tramiteId}`;
    return this.http.get<any>(url);
  }

  notifyTramiteStatusChange(tramiteId: string, status: string) {
    this.tramiteStatusChangedSource.next({ tramiteId, status });
  }

  getTramiteHistory(tramiteId: string | number){
    const headers = getHeaders();
    return this.http.get<any>(`${this.baseUrl}/miveracruz/citizen_form_request_history/${tramiteId}`, { headers });
  }

  createDocumentSigned(rawJson: string){
    const headers = getHeaders();
    return this.http.post<any>(`${this.baseUrl}/miveracruz/document_signed`, rawJson, { headers });
  }
}

export function getHeaders(): HttpHeaders {
  let headers = new HttpHeaders().set('Content-Type', 'application/json');
  const currentUser = localStorage.getItem('currentUser');
  if (currentUser) {
    const user = JSON.parse(currentUser);
    if (user.token) {
      console.log('Token:', user.token); // Log del token
      headers = headers.set('Authorization', `${user.token}`);
    } else {
      console.error('Token no encontrado en el objeto usuario:', user);
    }
  } else {
    console.error('No se encontró currentUser en localStorage.');
  }
  return headers;
}
