import { Injectable, HostListener, Inject, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/components/services/auth/authentication.service';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	isAdmin?: boolean;
	isBusiness?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnInit {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window,
	private authService: AuthenticationService
	) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}
	
	ngOnInit() {
		
	}
	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
		
	}
	MENUITEMS: Menu[] =[
		{
			title: 'Dashboard', path: '/dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		/*{
			title: 'Maquinaria', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/products/product-list', title: 'Lista Máquinas', type: 'link' },
				{ path: '/products/add-product', title: 'Agregar Maquinaria', type: 'link' },
				{ path: '/products/catalogs', title: 'Catálogos', type: 'link' },
				//{ path: '/products/sub-category', title: 'Sub Categorias', type: 'link' },
			]
		},
		{
			title: 'Refacciones', icon: 'tool', type: 'sub', active: false, children: [
				{ path: '/products/parts-list', title: 'Lista Refacciones', type: 'link' },
				{ path: '/products/parts/add-product', title: 'Agregar Refacción', type: 'link' },
			]
		},
		{
			title: 'Servicios', icon: 'clipboard', type: 'sub', active: false, children: [
				{ path: '/sales/services', title: 'Lista Servicios', type: 'link' },
				{ path: '/sales/services/add-service', title: 'Agregar Servicio', type: 'link' },
			]
		},
		{
			title: 'Souvenirs', icon: 'gift', type: 'sub', active: false, children: [
				{ path: '/products/souvenirs', title: 'Lista Souvenirs', type: 'link' },
				{ path: '/products/souvenirs/add-souvenir', title: 'Agregar Souvenir', type: 'link' },
			]
		},*/
		/*{
			title: 'Clientes', path: '/clients',  icon: 'user-check', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Cotizaciones', path: '/sales/quotations',  icon: 'check-square', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Pedidos', path: '/sales/orders', icon: 'shopping-cart', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Pagos', path: '/sales/transactions', icon: 'dollar-sign', type: 'link', active: false
		},*/
		/*{
			title: 'Cupones', icon: 'tag', type: 'sub', active: false, children: [
				{ path: '/coupons/list-coupons', title: 'Lista Cupones', type: 'link' },
				{ path: '/coupons/create-coupons', title: 'Crear Cupón', type: 'link' },
			]
		},*/
		/*{
			title: 'Páginas', icon: 'clipboard', type: 'sub', active: false, children: [
				{ path: '/pages/list-page', title: 'Lista Páginas', type: 'link' },
				{ path: '/pages/create-page', title: 'Crear Página', type: 'link' },
			]
		},
		{
			title: 'Menús', icon: 'align-left', type: 'sub', active: false, children: [
				{ path: '/menus/list-menu', title: 'Lista de Menú', type: 'link' },
				{ path: '/menus/create-menu', title: 'Crear Menú', type: 'link' },
			]
		},*/
		/*{
			title: 'Media', path: '/media', icon: 'camera', type: 'link', active: false
		},*/
		// {
		// 	title: 'Registro estatal', path: '/users/business', icon: 'map-pin', type: 'link', badgeType: 'primary', active: false
		// },
		{
			title: 'Reportes', icon: 'map-pin', type: 'sub', children: [
				{path: '/reports', title: 'Turnados', type: 'link'},
				{path: '/reports/not-assigned', title: 'Sin turnar', type: 'link'}
			]
		},
		{
			// title: 'Reportes', path: '/reports', icon: 'bar-chart-2', type: 'link', badgeType: 'primary', active: false
			title: 'Estadísticas', icon: 'bar-chart-2', type: 'sub', children: [
				{path: '/reports/general', title: 'General', type: 'link'},
				{path: '/reports/area', title: 'Por área', type: 'link'},
				{path: '/reports/fuente', title: 'Por fuente', type: 'link'},
				{path: '/reports/ciudadano', title: 'Por ciudadano', type: 'link'},
				{path: '/reports/colonia', title: 'Por colonia', type: 'link'}
			]
		},
		{
			title: 'Usuarios', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users', title: 'Ciudadanos', type: 'link' },
				{ path: '/users/neighborhood', title: 'Jefes de manzana', type: 'link' },
				{ path: '/users/officers', title: 'Enlace', type: 'link' },
				{ path: '/users/supervisors', title: 'Supervisores', type: 'link' },
				{ path: '/users/admins', title: 'Administradores', type: 'link' },
			]
		},
		{
			title: 'Mi Veracruz 2.0+', icon: 'list', type: 'sub', active: false, children: [
				{ title: 'Trámites', type: 'sub', active: false, children: [
					{ path: '/catalogs/bandeja', title: 'Bandeja', type: 'link' },
				] }
			]
		},
		{
			title: 'Catálogos', icon: 'list', type: 'sub', active: false, children: [
				{ path: '/catalogs/areas', title: 'Áreas', type: 'link' },
				{ path: '/catalogs/dependencias', title: 'Dependencias', type: 'link' },
				{ path: '/catalogs/services', title: 'Servicios', type: 'link' },	
 				{ path: '/catalogs/create-tramite', title: 'Trámites', type: 'link'},
				{ path: '/catalogs/sources', title: 'Fuentes', type: 'link' },
				{ path: '/catalogs/users', title: 'Perfiles', type: 'link' },
			]
		},
		{
			title: 'Viajes', path: '/trips', icon: 'navigation-2', type: 'link', badgeType: 'primary', active: false
		}	
		// {
		// 	title: 'Encuestas', path: '/surveys', icon: 'clipboard', type: 'link', badgeType: 'primary', active: false
		// },
		// {
		// 	title: 'Ocupación hotelera', path: '/surveys/occupation', icon: 'server', type: 'link', badgeType: 'primary', active: false
		// },
		// {
		// 	title: 'Reportes', path: '/reports', icon: 'bar-chart-2', type: 'link', active: false
		// },
		// {
		// 	title: 'Usuarios', icon: 'user-plus', type: 'sub', active: false, children: [
		// 		{ path: '/users', title: 'Negocios', type: 'link' },
		// 		{ path: '/users/admins', title: 'Administradores', type: 'link' },
		// 	]
		// },
		/*{
			title: 'Proveedores', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/vendors/list-vendors', title: 'Lista Proveedores', type: 'link' },
				{ path: '/vendors/create-vendors', title: 'Crear Proveedor', type: 'link' },
			]
		},*/
		/*{
			title: 'Localización', icon: 'chrome', type: 'sub', children: [
				{ path: '/localization/translations', title: 'Traducciones', type: 'link' },
				{ path: '/localization/currency-rates', title: 'Tipo de Cambio', type: 'link' },
				{ path: '/localization/taxes', title: 'Impuestos', type: 'link' },
			]
		},*/
		/*{
			title: 'Reportes', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		},
		{
			title: 'Facturas', path: '/invoice', icon: 'archive', type: 'link', active: false
		},
		{
			title: 'Ajustes', icon: 'settings', type: 'sub', children: [
				{ path: '/settings/profile', title: 'Perfil', type: 'link' },
			]
		},
		{
			title: 'Iniciar sesión',path: '/iniciar-sesion', icon: 'log-in', type: 'link', active: false
		}*/
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

	/*Menú User*/
	MENUITEMSUSER: Menu[] =[
		{
			title: 'Inicio', path: '/menus', icon: 'menu', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Mis reportes', path: '/reports/my-user-reports', icon: 'monitor', type: 'link', badgeType: 'primary', active: false
		},
		// {
		// 	title: 'Agregar reporte', path: '/reports/my-user-reports/create-report', icon: 'clipboard', type: 'link', badgeType: 'primary', active: false
		// },
		// {
		// 	title: 'Mis viajes', path: '/trips/my-trips', icon: 'map', type: 'link', badgeType: 'primary', active: false
		// },
	]
	itemsUser = new BehaviorSubject<Menu[]>(this.MENUITEMSUSER);


	/*Menu Área*/
	MENUAREA: Menu[] =[
		{
			title: 'Dashboard', path: '/dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Mis reportes', path: '/reports/my-area-reports', icon: 'monitor', type: 'link', badgeType: 'primary', active: false
		},
		// {
		// 	title: 'Ordenes de trabajo', path: '/work-orders', icon: 'clipboard', type: 'link', badgeType: 'primary', active: false
		// },
	]
	itemsArea = new BehaviorSubject<Menu[]>(this.MENUAREA);

	/*Menu Supervisor*/
	MENUSUPERVISOR: Menu[] =[
		{
			title: 'Dashboard', path: '/dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Reportes', icon: 'map-pin', type: 'sub', children: [
				{path: '/reports', title: 'Turnados', type: 'link'},
				{path: '/reports/not-assigned', title: 'Sin turnar', type: 'link'}
			]
		},
		{
			title: 'Estadísticas', icon: 'bar-chart-2', type: 'sub', children: [
				{path: '/reports/general', title: 'General', type: 'link'},
				{path: '/reports/area', title: 'Por área', type: 'link'},
				{path: '/reports/fuente', title: 'Por fuente', type: 'link'},
				{path: '/reports/ciudadano', title: 'Por ciudadano', type: 'link'},
				{path: '/reports/colonia', title: 'Por colonia', type: 'link'}
			]
		},
	]
	itemsSupervisor = new BehaviorSubject<Menu[]>(this.MENUSUPERVISOR);

	/*Menú SuperAdmin*/
	MENUSUPERADMIN: Menu[] =[
		{
			title: 'Dashboard', path: '/dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Reportes', icon: 'map-pin', type: 'sub', children: [
				{path: '/reports', title: 'Turnados', type: 'link'},
				{path: '/reports/not-assigned', title: 'Sin turnar', type: 'link'}
			]
		},
		{
			title: 'Estadísticas', icon: 'bar-chart-2', type: 'sub', children: [
				{path: '/reports/general', title: 'General', type: 'link'},
				{path: '/reports/area', title: 'Por área', type: 'link'},
				{path: '/reports/fuente', title: 'Por fuente', type: 'link'},
				{path: '/reports/ciudadano', title: 'Por ciudadano', type: 'link'},
				{path: '/reports/colonia', title: 'Por colonia', type: 'link'}
			]
		},
		{
			title: 'Usuarios', icon: 'user-plus', type: 'sub', active: false, children: [
				{ path: '/users', title: 'Ciudadanos', type: 'link' },
				{ path: '/users/neighborhood', title: 'Jefes de manzana', type: 'link' },
				{ path: '/users/officers', title: 'Enlace', type: 'link' },
				{ path: '/users/supervisors', title: 'Supervisores', type: 'link' },
				{ path: '/users/admins', title: 'Administradores', type: 'link' },
			]
		},
		{
			title: 'Catálogos', icon: 'list', type: 'sub', active: false, children: [
				{ path: '/catalogs/areas', title: 'Áreas', type: 'link' },
				{ path: '/catalogs/services', title: 'Servicios', type: 'link' },
				{ path: '/catalogs/formalities', title: 'Trámites', type: 'link'},
				{ path: '/catalogs/sources', title: 'Fuentes', type: 'link' },
				{ path: '/catalogs/users', title: 'Perfiles', type: 'link' },
			]
		},
		{
			title: 'Viajes', path: '/trips', icon: 'navigation-2', type: 'link', badgeType: 'primary', active: false
		}		
	]
	itemsSuperAdmin = new BehaviorSubject<Menu[]>(this.MENUSUPERADMIN);
}
