<div class="container my-4 contenedor-especificaciones">
  <!-- Título -->
  <div class="w-100">
    <div class="w-auto">
      <h2 class="titulo">Especificaciones de la ficha</h2>
    </div>
    <div class="w-auto">
      <hr>
    </div>
  </div>
  
  <!-- Primera fila: Estado de la ficha y opciones -->
  <div class="row mb-4">
    <div class="col-md-6">
      <div class="mb-3">
        <label>Estado de la ficha:</label>
        <p>Solicitudes suspendidas</p>
      </div>

      <div class="mb-3">
        <label>Estado Publicación:</label>
        <p>Ficha sin publicar</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-check form-switch mb-3">
        <input class="form-check-input" type="checkbox" id="limitarSolicitudes">
        <label for="limitarSolicitudes">Limitar las solicitudes del trámite por ciudadano</label>
        <p>
          El trámite será limitado a una solicitud por ciudadano hasta que el trámite haya finalizado. (Un solo trámite en proceso a la vez).
        </p>
      </div>
      <div class="form-check form-switch mb-3">
        <input class="form-check-input" type="checkbox" id="publicarTramite">
        <label for="publicarTramite">Publicar trámite</label>
        <p>
          La siguiente opción, la información de este trámite será PÚBLICA para el ciudadano.
        </p>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="permitirSolicitar">
        <label for="permitirSolicitar">Permitir al ciudadano solicitar trámite</label>
        <p>
          Permitirá que el ciudadano haga una solicitud de este trámite. Si desea editar este trámite, primero deberá suspender esta opción.
        </p>
      </div>
    </div>
  </div>
  
  <!-- Segunda fila: Modalidades -->
  <div class="row mb-4">
    <div class="col">
      <label style="display: inline">Selecciona tipo de modalidad(es):</label>
      <div style="display: inline" class="btn-group" role="group" aria-label="Modalidad de trámite">
        <button type="button" class="btn btn-enlinea" (click)="$event.target.classList.toggle('selected')">
          Modalidad en línea
        </button>
        <button type="button" class="btn btn-presencial" (click)="$event.target.classList.toggle('selected')">
          Modalidad presencial
        </button>
        <button type="button" class="btn btn-concita" (click)="$event.target.classList.toggle('selected')">
          Trámite con cita
        </button>
      </div>
    </div>
  </div>
  
  <!-- Tercera fila: Clave y nombre del trámite -->
  <div class="row">
    <div class="col-md-6">
      <div class="mb-3">
        <label for="claveRegistro">Clave o código de registro<span class="text-danger">*</span></label>
        <div class="input-group">
          <input type="text" class="form-control" id="claveRegistro">
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-3">
        <label for="nombreTramite">Nombre del trámite<span class="text-danger">*</span></label>
        <div class="input-group">
          <input type="text" class="form-control" id="nombreTramite">
        </div>
      </div>
    </div>
  </div>
</div>