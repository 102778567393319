import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { catchError, map } from 'rxjs/operators';
import { ToastyService, ToastyConfig, ToastOptions } from 'ng2-toasty';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationType } from '../../interfaces/notification.enums';
import { UserModel } from '../../auth/auth.models';
import { UsersService } from '../../services/users/users.service';
import { MensajesService } from '@/app/shared/service/mensajes.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formpassword: FormGroup;
  public formAngel: FormGroup;
  public submitted = false;
  public isLoading = false;
  public onEdit = false;
  public userId: any;
  public disabled = true;

  public location = 'Data';

  private subs: Array<Subscription> = [];
  public user: any;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private authService: AuthenticationService,
    private toastyService: ToastyService,
    private toastyConfig: ToastyConfig,
    private activatedRoute: ActivatedRoute,
    private mensajesService: MensajesService,
    private router: Router
  ) {
    this.toastyConfig.theme = 'bootstrap';
  }

  ngOnInit(): void {
    this.userId = this.activatedRoute.snapshot.paramMap.get('id');
    this.authService.getUserV2(this.userId)
      .subscribe(user => {
        console.log(user);
        this.user = user;
      }, error => {
        console.log(error);
      });

    this.buildUser();
    this.buildFormAngel();
    this.buildPassword();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private validatePasswords(): void {
    const password: string = this.formpassword.get('password').value;
    const confirmPassword: string = this.formpassword.get('confirmedPassword').value;

    if (password !== confirmPassword) {
      this.formpassword.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  public get formState(): any {
    return this.form.controls;
  }

  public get formStatePass(): any {
    return this.formpassword.controls;
  }

  public get formStateAngel(): any {
    return this.formAngel.controls;
  }

  public buildUser(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, Validators.pattern("[0-9]{10}")]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    })
  }

  public buildFormAngel(): void {
    this.formAngel = this.fb.group({
      ContactName: ['', [Validators.required]],
      ContactPhoneNumber: ['', [Validators.required]]
    })
  }

  private buildUserUpdate(): UserModel {
    return {
      firstName: this.form.controls['firstName'].value,
      lastName: this.form.controls['lastName'].value,
      email: this.form.controls['email'].value,
      phoneNumber: this.form.controls['phoneNumber'].value
    }
  }
  private buildAngel(): UserModel {
    return {
      ContactName: this.formAngel.controls['ContactName'].value,
      ContactPhoneNumber: this.formAngel.controls['ContactPhoneNumber'].value
    }
  }
  public buildPassword(): void {
    this.formpassword = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirmedPassword: ['', Validators.required],
    });
  }

  updateUser() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    const user: UserModel = this.buildUserUpdate();
    this.isLoading = true;
    this.subs.push(
      this.userService.updateUser(this.userId, user)
        .pipe(
          map(() => {
            this.handleUserEdit();
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  }

  onChangeAngel(): void {
    this.submitted = true;
    if (this.formAngel.invalid) {
      return;
    }
    const angel: UserModel = this.buildAngel();
    this.isLoading = true;
    this.subs.push(
      this.userService.updateUser(this.userId, angel)
        .pipe(
          map(() => {
            this.handleAngelEdit();
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }),
          catchError(error => this.handleAngelError(error))
        )
        .subscribe()
    )
  }

  public onChangePassword(): void {
    this.submitted = true;
    this.validatePasswords();

    if (this.formpassword.invalid) {
      this.isLoading = false;
      return;
    }
    this.subs.push(
      this.authService.changePassword(this.formpassword.value)
        .pipe(catchError(err => {
          this.sendNotification('Error al cambiar la contraseña', err.error?.message, NotificationType.error);
          return throwError(err);
        }))
        .subscribe(() => this.sendNotification(null, 'Contraseña actualizada correctamente', NotificationType.success))
    );
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  private setValues(user: any): void {
    const values = this.authService.mapRequiredValues(user);
    //console.log(values);
    const { controls } = this.form;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.form.controls[value].setValue(values[value]);
      }
    }
  }

  private setValuesPassword(userpass: any): void {
    const values = this.authService.mapRequiredValuesForPassword(userpass);
    const { controls } = this.formpassword;

    for (const value in values) {
      if (controls.hasOwnProperty(value)) {
        this.formpassword.controls[value].setValue(values[value]);
      }
    }
  }

  private setValuesAngel(user: any): void {
    const values = this.authService.mapRequiredValuesForAngel(user);
    const { controls } = this.formAngel;
    for (const value in values) {
      console.log(value)
      if (controls.hasOwnProperty(value)) {
        this.formAngel.controls[value].setValue(values[value]);
      }
    }
  }

  private sendNotification(title: string = 'Morelos', message: string, type: string): void {
    const toastOptions: ToastOptions = {
      title,
      msg: message,
      showClose: true,
      timeout: 3500,
      theme: 'bootstrap'
    };

    this.toastyService[type](toastOptions);
  }

  private handleUserEdit() {
    const toastOptions: ToastOptions = {
      title: 'Perfil',
      msg: 'Usuario actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }
  private handleAngelEdit() {
    const toastOptions: ToastOptions = {
      title: 'Perfil',
      msg: 'Ángel de la Guarda actualizado correctamente',
      showClose: true,
      timeout: 1700
    };

    this.toastyService.success(toastOptions);
  }
  private handleError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudo actualizar el usuario',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  private handleAngelError(error: any) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: 'No se pudieron actualizar los datos del Ángel de la Guarda',
      showClose: true,
      timeout: 2000
    };

    this.toastyService.error(toastOptions);

    return [];
  }

  logout() {
    this.mensajesService.emite('Logout');
    localStorage.removeItem('currentUser');
    this.router.navigateByUrl('/ciudadano/inicio');
    this.ngOnInit();
  }
}
